import MEETING_STRUCT from '../../entities/meeting';

const CALCULATED_MEETING_STRUCT = {
  ...MEETING_STRUCT,
  DATE: 'date',
  TIME: 'time',
  REP_ACTUAL_START: 'actualStartRep',
  REP_ACTUAL_END: 'actualEndRep',
};

export default CALCULATED_MEETING_STRUCT;
