const STRUCT = {
  GAME_ID: 'gameId',
  MEETINGS: 'meetings',
};

export const GRADES_MEETING_STRUCT = {
  SIXIB_RESULT: 'sixIbResult',
  ASSERTIVENESS_GRADE: 'assertiveness',
  CLIENT_ID: 'client_id',
  CLIENT_NAME: 'client',
  CLIENT_EMAIL: 'client_email',
  COMMENT: 'comment',
  MEETING_START: 'start',
  MEETING_END: 'end',
  GAME_ID: 'gameId',
  MEETING_ID: 'id',
  INSTITUTION: 'institution',
  INSTITUTION_ID: 'institution_id',
  POSITION: 'position',
  POSITION_ID: 'position_id',
  QUESTION_FORM_RESULT: 'questionFormResult',
  REPRESENTATIVE: 'representative',
  REPRESENTATIVE_EMAIL: 'representative_email',
  TEAM: 'team',
};

export const SIXIB_QUESTIONS_STRUST = {
  QUESTION: 'question',
  AVERAGE_GRADE: 'averageGrade',
};

export const ASSERTIVENNES_GRADES_STRUCT = {
  TITLE: 'title',
  AVERAGE_GRADE: 'averageGrade',
};

export const COMMENT_STRUCT = {
  DATE: 'date',
  CLIENT: 'client',
  POSITION: 'position',
  INSTITUTION: 'institution',
  COMMENT: 'comment',
};

export const QUESTION_RESULT_STRUCT = {
  DATE: 'date',
  CLIENT: 'client',
  POSITION: 'position',
  INSTITUTION: 'institution',
  QUESTION_RESULT: 'questionResult',
};

export default STRUCT;
