import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { branch } from 'baobab-react/higher-order';

import {
  meSelector,
  sixIbsCategorySelector,
  sixIbsParamSelector,
} from '../../../../store/struct/selectors';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';

import styles from '../index.module.scss';
import forOwn from 'lodash.forown';
import { PARAMS } from '../../../../components/form/select';

const DEFAULT_GRADE = 'N/A';

const ID = 'id';
const STEP = 'step';
const NAME = 'name';
const DATA = 'data';
const CATEGORY = 'category';
const QUESTIONS = 'questions';

const Estimates = ({
  sixIbsQuestions,
  sixIbsLegend,
  onChange,
  gradesState,
  isResultEditing,
}) => {
  const [isCategoryNull, setIsCategoryNull] = useState(false);

  const legendList = useMemo(() => {
    try {
      if (sixIbsLegend) {
        return JSON.parse(sixIbsLegend);
      }
      return [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  }, [sixIbsLegend]);

  const questionForm = useMemo(() => {
    try {
      let questionID = 0;
      if (sixIbsQuestions) {
        return JSON.parse(sixIbsQuestions).map((field, fieldIndex) => {
          if (!field[CATEGORY]) {
            setIsCategoryNull(true);
          }
          return {
            [ID]: fieldIndex,
            [STEP]: field[CATEGORY] ? fieldIndex + 1 : null,
            [NAME]: field[CATEGORY] ? field[CATEGORY] : null,
            [DATA]: field[QUESTIONS].map((param, paramIdx) => {
              questionID = questionID + 1;
              return {
                id: questionID,
                name: param,
              };
            }),
          };
        });
      }
      return [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  }, [sixIbsQuestions]);

  const OPTIONS = legendList.map((item, index) => {
    return {
      [PARAMS.ID]: index,
      [PARAMS.TITLE]: `${index} ${item}`,
    };
  });

  const [state, setState] = useState();
  const [initialStateFilled, setInitialStateFilled] = useState(false);

  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: () => '',
        min: true,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.name,
      },
      {
        type: TABLE_TYPES.SELECT,
        onChange: (data, value) => setState({ ...state, [data.id]: value }),
        getValue: data => {
          return state ? state[data.id] : 0;
        },
        placeholder: DEFAULT_GRADE,
        options: OPTIONS,
        min: true,
        showZeroValue: isResultEditing,
      },
    ],
    [OPTIONS, isResultEditing, state],
  );

  const tableGroupsConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.step,
        min: true,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.name,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: () => '',
        min: true,
      },
    ],
    [],
  );

  useEffect(() => {
    const sixIbsMeetingGrades = [];
    forOwn(state, (val, key) => {
      if (!isNaN(val)) {
        sixIbsMeetingGrades.push([Number(key), Number(val)]);
      }
    });
    onChange(sixIbsMeetingGrades);
  }, [state, onChange]);

  useEffect(() => {
    if (!initialStateFilled && gradesState.length > 0) {
      const preparedData = gradesState.reduce((acc, curr) => {
        acc[curr[0]] = curr[1];
        return acc;
      }, {});
      setState(preparedData);
      setInitialStateFilled(true);
    }
  }, [gradesState, initialStateFilled]);

  return (
    <Table
      className={styles.estimatesTable}
      headers={[isCategoryNull ? null : 'Этап', 'Оценка', 'Значение']}
      config={tableConfig}
      groupsConfig={isCategoryNull ? null : tableGroupsConfig}
      data={isCategoryNull ? questionForm[0].data : questionForm}
    />
  );
};

Estimates.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default branch(
  {
    currentUser: meSelector(),
    sixIbsCategory: sixIbsCategorySelector(),
    sixIbsParam: sixIbsParamSelector(),
  },
  Estimates,
);
