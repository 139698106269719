import React, { useCallback, useState, useMemo } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'prop-types';
import find from 'lodash.find';

import {
  agentsSelector,
  clientMeetingsSelector,
  clientObserverMeetingsSelector,
  clientTimeslotsSelector,
  eventsSelector,
  investsSelector,
  meetingsSelector,
  meSelector,
  reportsSelector,
  teamsSelector,
  allTimeslotsClientSelector,
  clientAgentsMeetingsSelector,
  managerClientsSelector,
  clientsSelector,
  meetingsResultsSelector,
} from '../../../store/struct/selectors';
import MEETING_STRUCT, {
  MEETING_STATUSES,
} from '../../../store/struct/entities/meeting';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import RESULT_STRUCT from '../../../store/struct/entities/meetingResult';
import AGENT_STRUCT from '../../../store/struct/entities/agent';
import {
  getClientEventsIds,
  getClientInvestIds,
  getResultCoef,
} from '../../../store/struct/entities/invest';
import REPORT_STRUCT from '../../../store/struct/entities/report';
import TIMESLOT_STRUCT, {
  BREAK_VALUES,
} from '../../../store/struct/entities/timeslots';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import MeetingDialog from './meetingDialog';
import AcceptDeclineMeetingDialog from './acceptDeclineMeetingDialog';
import {
  createMeeting,
  updateMeeting,
  removeMeeting,
} from '../../../store/struct/entities/meeting/actions';
import {
  sendMeetingResult,
  updateMeetingResult,
} from '../../../store/struct/entities/meetingResult/actions';
import { changeTimeslotAvailability } from '../../../store/struct/entities/timeslots/actions';
import { getById } from '../../../utils';

import InvestDialog from '../investDialog';
import ResultDialog from '../resultDialog';
import { THEMES } from '../../../components/button';
import StatusText from '../../../components/status-text/status-text';
import Select from '../../../components/form/select';

import styles from './index.module.scss';
import CALCULATED_MEETING_STRUCT from '../../../store/struct/calculated/meeting';
import { meetingsSort } from '../../../store/struct/calculated';
import { isDatesEqual, getUTCDate } from '../../../utils/time';

import commonStyles from '../../../styles/common.module.scss';

import { TABS } from '../index';
import ColoredText from '../../../components/colored-text/colored-text';

const HEADERS = ['Врач', 'Представитель', 'Статус', 'Действие'];

const Meetings = ({
  agents,
  events,
  invests,
  meetings,
  dispatch,
  reports,
  managerClients,
  clientMeetings,
  clientObserverMeetings,
  clientTimeslots,
  switchTab,
  allTimeslots,
  clientAgentsMeetings,
  allClients,
  meetingsResults,
}) => {
  // we have timeslots in the meetings state for now

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [investDialogVisible, setInvestDialogVisible] = useState(false);
  const [resultDialogVisible, setResultDialogVisible] = useState(false);
  const [meetingStarted, setMeetingStarted] = useState(true);
  const [acceptDeclineMeeting, setAcceptDeclineMeeting] = useState(null);
  const [confirmMeeting, setConfirmMeeting] = useState(null);
  const [selectedGameDay, setSelectedGameDay] = useState(
    new Date().toLocaleDateString(),
  );
  const [showTimeslotWarnig, setShowTimeslotWarnig] = useState(false);
  const [meetingWithResults, setMeetingWithResults] = useState(null);

  const observerMeetings = useMemo(
    () =>
      clientObserverMeetings.filter(
        meeting => meeting[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.done,
      ),
    [clientObserverMeetings],
  );

  const onMeetingJoin = url => {
    window.open(`${url}`, '_blank');
  };

  const clientAndObserverMeetings = useMemo(() => {
    return clientMeetings.concat(
      observerMeetings.filter(meeting => {
        const idx = clientMeetings.findIndex(
          meet =>
            meet[CALCULATED_MEETING_STRUCT.TIME] ===
            meeting[CALCULATED_MEETING_STRUCT.TIME],
        );
        if (idx > -1) {
          return false;
        } else {
          return true;
        }
      }),
    );
  }, [clientMeetings, observerMeetings]);

  const allMeetings = useMemo(
    () =>
      clientAndObserverMeetings
        .concat(clientTimeslots)
        .sort(meetingsSort)
        .filter(meeting => {
          if (selectedGameDay) {
            return meeting[CALCULATED_MEETING_STRUCT.DATE] === selectedGameDay;
          } else {
            return true;
          }
        }),
    [clientAndObserverMeetings, clientTimeslots, selectedGameDay],
  );

  const gameDays = useMemo(() => {
    return [
      ...new Set(
        clientTimeslots.map(
          timeslot => timeslot[CALCULATED_MEETING_STRUCT.DATE],
        ),
      ),
    ].map((value, index) => {
      return { id: value, title: value };
    });
  }, [clientTimeslots]);

  const selectGameDay = useCallback(day => {
    setSelectedGameDay(day);
  }, []);

  const resetGameDay = useCallback(() => {
    setSelectedGameDay(null);
  }, []);

  const currentMeeting = useMemo(
    () =>
      find(meetings, { [MEETING_STRUCT.STATUS]: MEETING_STATUSES.inProgress }),
    [meetings],
  );
  if (currentMeeting && !selectedMeeting) {
    setMeetingStarted(true);
    setSelectedMeeting(currentMeeting);
  } else if (selectedMeeting && !currentMeeting) {
    setMeetingStarted(false);
    setSelectedMeeting(null);
  }

  const onTimeslotAvailabilityChange = useCallback(
    (meetingData, breakValue) => {
      const meetings = allMeetings
        .filter(meet => {
          return (
            meet[CALCULATED_MEETING_STRUCT.DATE] ===
              meetingData[CALCULATED_MEETING_STRUCT.DATE] &&
            meet[CALCULATED_MEETING_STRUCT.TIME] ===
              meetingData[CALCULATED_MEETING_STRUCT.TIME]
          );
        })
        .map(meet => meet[MEETING_STRUCT.ID]);
      dispatch(changeTimeslotAvailability, {
        ids: meetings,
        break: breakValue,
      });
    },
    [allMeetings, dispatch],
  );

  const onAcceptMeetingConfirmation = useCallback(
    meetingData => {
      dispatch(updateMeeting, {
        id: meetingData[MEETING_STRUCT.MEETING_ID],
        timeslot_id: meetingData[MEETING_STRUCT.ID],
        confirm: true,
        siteUrl: document.location.href,
      });
    },
    [dispatch],
  );

  const onCloseAcceptDeclineDialog = useCallback(() => {
    acceptDeclineMeeting && setAcceptDeclineMeeting(null);
    confirmMeeting && setConfirmMeeting(null);
  }, [acceptDeclineMeeting, confirmMeeting]);

  const onAcceptMeeting = useCallback(() => {
    if (confirmMeeting) {
      onAcceptMeetingConfirmation(confirmMeeting);
      setConfirmMeeting(null);
    } else {
      onTimeslotAvailabilityChange(acceptDeclineMeeting, BREAK_VALUES.NO);
      setAcceptDeclineMeeting(null);
    }
  }, [
    acceptDeclineMeeting,
    confirmMeeting,
    onAcceptMeetingConfirmation,
    onTimeslotAvailabilityChange,
  ]);

  const onDeclineMeeting = useCallback(() => {
    if (confirmMeeting) {
      dispatch(removeMeeting, {
        timeslot_id: confirmMeeting[MEETING_STRUCT.ID],
        siteUrl: document.location.href,
      });
      setConfirmMeeting(null);
    } else {
      dispatch(removeMeeting, {
        timeslot_id: acceptDeclineMeeting[MEETING_STRUCT.ID],
      });
      setAcceptDeclineMeeting(null);
    }
  }, [acceptDeclineMeeting, confirmMeeting, dispatch]);

  const acceptDeclineMeetingAgentName = useMemo(() => {
    if (acceptDeclineMeeting || confirmMeeting) {
      return agents.find(
        agent =>
          agent[AGENT_STRUCT.ID] ===
          ((acceptDeclineMeeting &&
            acceptDeclineMeeting[MEETING_STRUCT.REPRESENTATIVE_ID]) ||
            confirmMeeting[MEETING_STRUCT.REPRESENTATIVE_ID]),
      )[AGENT_STRUCT.NAME];
    }
    return;
  }, [acceptDeclineMeeting, agents, confirmMeeting]);

  const clearTimeslotWarning = useCallback(() => {
    setShowTimeslotWarnig(false);
  }, []);

  const onRescheduleMeeting = useCallback(
    async timeslotId => {
      const userId =
        confirmMeeting && confirmMeeting[MEETING_STRUCT.REPRESENTATIVE_ID];
      const timeslot = getById(allTimeslots, timeslotId);
      const agentMeetingInTimeslot = clientAgentsMeetings.find(meeting => {
        return (
          meeting[MEETING_STRUCT.START] === timeslot[TIMESLOT_STRUCT.START] &&
          meeting[MEETING_STRUCT.REPRESENTATIVE_ID] === userId
        );
      });

      if (!timeslotId) {
        onCloseAcceptDeclineDialog();
        return;
      }

      if (agentMeetingInTimeslot) {
        setShowTimeslotWarnig(true);
        return;
      }

      if (timeslot.meeting_id) {
        setShowTimeslotWarnig(true);
      } else {
        const data = {
          timeslot_id: timeslotId,
          representative_id: userId,
        };
        dispatch(createMeeting, {
          ...data,
          rescheduleTimeslotId: confirmMeeting[MEETING_STRUCT.ID],
          siteUrl: document.location.href,
        });
        onCloseAcceptDeclineDialog();
      }
    },
    [
      allTimeslots,
      clientAgentsMeetings,
      confirmMeeting,
      dispatch,
      onCloseAcceptDeclineDialog,
    ],
  );

  const onMeetingStart = useCallback(
    meetingData => {
      // Do not dispatch an event if the meeting starts in the second time.
      if (meetingData[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.inProgress) {
        dispatch(updateMeeting, {
          id: meetingData[MEETING_STRUCT.MEETING_ID],
          timeslot_id: meetingData[MEETING_STRUCT.ID],
          // When meeting was started, agent can't remove this meeting
          started: true, // send flag of starting
        });
      }
    },
    [dispatch],
  );

  const onMeetingChangeGrades = useCallback(
    meetingData => {
      const meeting = find(meetingsResults, {
        [MEETING_STRUCT.ID]: meetingData[MEETING_STRUCT.MEETING_ID],
      });
      setMeetingWithResults(meeting);
    },
    [meetingsResults],
  );

  const onCloseMeetingDialog = useCallback(() => {
    setMeetingWithResults(null);
  }, []);

  const onMeetingEnd = useCallback(
    ({
      gradesState,
      questionFormResult,
      assertivenessGradesState,
      comment,
      status,
    }) => {
      const currentClient = managerClients.find(client => {
        if (selectedMeeting) {
          return (
            client[CLIENT_STRUCT.ID] ===
            selectedMeeting[MEETING_STRUCT.CUSTOMER_ID]
          );
        } else if (meetingWithResults) {
          return (
            client[CLIENT_STRUCT.ID] ===
            meetingWithResults[MEETING_STRUCT.CUSTOMER_ID]
          );
        }
        return undefined;
      });
      const coef = getResultCoef(
        getClientEventsIds(selectedMeeting, invests),
        events,
        invests,
        currentClient,
      );
      const gradesWithCoef = {};
      gradesState.forEach(([key, val]) => {
        gradesWithCoef[key] = val * coef;
      });
      const assertivenessGrades = {};
      assertivenessGradesState.forEach(([key, val]) => {
        assertivenessGrades[key] = val;
      });
      if (meetingWithResults) {
        dispatch(updateMeetingResult, {
          [RESULT_STRUCT.MEETING]: {
            id: meetingWithResults[MEETING_STRUCT.ID],
          },
          [RESULT_STRUCT.MEETING_RESULT_ID]:
            meetingWithResults[RESULT_STRUCT.MEETING_RESULT_ID],
          [RESULT_STRUCT.GRADES]: gradesWithCoef,
          [RESULT_STRUCT.COMMENT]: comment,
          [RESULT_STRUCT.QUESTION_FORM_RESULT]: JSON.stringify(
            questionFormResult,
          ),
          [RESULT_STRUCT.ASSERTIVENESS_GRADES]: assertivenessGrades,
          [RESULT_STRUCT.CUSTOMER_ID]:
            meetingWithResults[MEETING_STRUCT.CUSTOMER_ID],
          [RESULT_STRUCT.STATUS]: MEETING_STATUSES.done,
        });
        setMeetingWithResults(null);
      } else {
        dispatch(sendMeetingResult, {
          [RESULT_STRUCT.MEETING]: {
            id: selectedMeeting[MEETING_STRUCT.MEETING_ID],
          },
          [RESULT_STRUCT.GRADES]: gradesWithCoef,
          [RESULT_STRUCT.COMMENT]: comment,
          [RESULT_STRUCT.QUESTION_FORM_RESULT]: JSON.stringify(
            questionFormResult,
          ),
          [RESULT_STRUCT.ASSERTIVENESS_GRADES]: assertivenessGrades,
          [RESULT_STRUCT.STATUS]: status,
        });
        setMeetingStarted(false);
      }
    },
    [
      managerClients,
      selectedMeeting,
      invests,
      events,
      meetingWithResults,
      dispatch,
    ],
  );

  const meetingsTableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const currClient = allClients.find(
          client =>
            client[CLIENT_STRUCT.ID] === data[MEETING_STRUCT.CUSTOMER_ID],
        );
        const name = currClient ? currClient[CLIENT_STRUCT.NAME] : '';

        return name;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      getComponentIndex: data => {
        const userId = data[MEETING_STRUCT.REPRESENTATIVE_ID];
        const agent = getById(agents, userId);

        if (agent && agent[AGENT_STRUCT.NAME]) {
          return 0;
        } else if (data[TIMESLOT_STRUCT.BREAK] === BREAK_VALUES.UNAVAILABLE) {
          return 2;
        } else {
          return 1;
        }
      },
      components: [
        {
          type: TABLE_TYPES.LINK,
          getValue: data => {
            const userId = data[MEETING_STRUCT.REPRESENTATIVE_ID];
            const agent = getById(agents, userId);

            return agent && agent[AGENT_STRUCT.NAME];
          },
          onClick: meetingData => {
            const userId = meetingData[MEETING_STRUCT.REPRESENTATIVE_ID];
            const agent = getById(agents, userId);
            switchTab(TABS.AGENTS_GRADES, {
              agentEmail: agent[AGENT_STRUCT.EMAIL],
              trigger: Date.now(),
            });
          },
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: data => {
            const breakValue =
              data[TIMESLOT_STRUCT.BREAK] === BREAK_VALUES.ALL
                ? 'Общеигровой перерыв'
                : data[TIMESLOT_STRUCT.BREAK] === BREAK_VALUES.CLIENT
                ? 'Индивидуальный перерыв'
                : 'Свободный слот';

            return (
              <ColoredText
                text={breakValue}
                status={data[TIMESLOT_STRUCT.BREAK]}
              />
            );
          },
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: data => 'Слот занят',
        },
      ],
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      getComponentIndex: data => {
        const userId = data[MEETING_STRUCT.REPRESENTATIVE_ID];
        const agent = getById(agents, userId);
        if (agent && agent[AGENT_STRUCT.NAME]) {
          return 0;
        } else {
          return 1;
        }
      },
      components: [
        {
          type: TABLE_TYPES.TEXT,
          getValue: data => {
            const time = data[CALCULATED_MEETING_STRUCT.TIME];
            const date = data[CALCULATED_MEETING_STRUCT.DATE];
            const status = data[MEETING_STRUCT.STATUS];

            return <StatusText text={`${date} ${time}`} status={status} />;
          },
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: data => {
            const time = data[CALCULATED_MEETING_STRUCT.TIME];
            const date = data[CALCULATED_MEETING_STRUCT.DATE];

            return (
              <ColoredText
                text={`${date} ${time}`}
                className={styles.marginLeft}
                status={
                  data[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.NO
                    ? data[TIMESLOT_STRUCT.BREAK]
                    : ''
                }
              />
            );
          },
        },
      ],
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      centered: true,
      getComponentIndex: currentMeeting => {
        const today = new Date();
        const meetingDate = currentMeeting[TIMESLOT_STRUCT.START];

        const userId = currentMeeting[MEETING_STRUCT.REPRESENTATIVE_ID];
        const agent = getById(agents, userId);

        if (agent && agent[AGENT_STRUCT.NAME]) {
          if (
            !isDatesEqual(today, meetingDate) &&
            !currentMeeting[MEETING_STRUCT.TEAMS_URL] &&
            currentMeeting[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.done
          ) {
            return 0;
          }
          if (currentMeeting[MEETING_STRUCT.TEAMS_URL]) {
            switch (currentMeeting[MEETING_STRUCT.STATUS]) {
              case MEETING_STATUSES.needConfirmation:
              case MEETING_STATUSES.pending:
              case MEETING_STATUSES.inProgress:
                return 4;
              default:
                return;
            }
          }
          if (
            currentMeeting[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.done &&
            currentMeeting[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.NO
          ) {
            return 7;
          }
          if (
            currentMeeting[MEETING_STRUCT.STATUS] ===
            MEETING_STATUSES.needConfirmation
          ) {
            return 8;
          }
          switch (currentMeeting[MEETING_STRUCT.STATUS]) {
            case MEETING_STATUSES.pending:
            case MEETING_STATUSES.inProgress:
              return 1;
            case MEETING_STATUSES.done:
              return 2;
            default:
              return;
          }
        } else if (
          currentMeeting[TIMESLOT_STRUCT.BREAK] === BREAK_VALUES.UNAVAILABLE
        ) {
          return;
        } else {
          const now = new Date();
          const start = currentMeeting[TIMESLOT_STRUCT.START];
          const startDate = getUTCDate(start);
          if (now.getTime() > startDate.getTime()) {
            return;
          } else {
            switch (currentMeeting[TIMESLOT_STRUCT.BREAK]) {
              case BREAK_VALUES.ALL:
                return;
              case BREAK_VALUES.CLIENT:
                return 5;
              case BREAK_VALUES.NO:
                return 6;
              default:
                return;
            }
          }
        }
      },
      components: [
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Не сегодня',
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: onMeetingStart,
          text: 'Начать',
          theme: THEMES.PRIMARY,
          min: true,
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: onMeetingChangeGrades,
          text: 'Оценка',
          theme: THEMES.PRIMARY,
          min: true,
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Будущая',
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data => onMeetingJoin(data[MEETING_STRUCT.TEAMS_URL]),
          text: 'MS Teams',
          theme: THEMES.CANCEL,
          min: true,
          getProps: data => ({
            disabled:
              data[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.inProgress,
          }),
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data => onTimeslotAvailabilityChange(data, BREAK_VALUES.NO),
          text: 'Открыть слот',
          theme: THEMES.SUCCESS,
          min: true,
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data =>
            onTimeslotAvailabilityChange(data, BREAK_VALUES.CLIENT),
          text: 'Закрыть слот',
          theme: THEMES.ERROR,
          min: true,
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data => setAcceptDeclineMeeting(data),
          text: 'Отменить/Принять',
          theme: THEMES.EDIT,
          min: true,
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data => setConfirmMeeting(data),
          text: 'Подтвердить',
          theme: THEMES.EDIT,
          min: true,
        },
      ],
    },
  ];

  const tableMeetingsHeaderConfig = [
    {},
    {},
    {},
    {
      style: { className: commonStyles.headerCentered },
    },
  ];

  const onCloseDialog = () => {
    setInvestDialogVisible(false);
    setResultDialogVisible(false);
    setSelectedMeeting(null);
  };

  let selectedReport;
  if (selectedMeeting) {
    selectedReport = find(
      reports,
      report =>
        report[REPORT_STRUCT.MEETING_ID] ===
        selectedMeeting[MEETING_STRUCT.MEETING_ID],
    );
  }

  return (
    <>
      <Select
        onChange={selectGameDay}
        options={gameDays}
        value={selectedGameDay}
        className={styles.selectMargin}
        placeholder='Все дни'
        selectablePlaceholder
        onReset={resetGameDay}
      />
      {allMeetings.length ? (
        <Table
          className={styles.tableWithControl}
          headers={HEADERS}
          headerConfig={tableMeetingsHeaderConfig}
          data={allMeetings}
          config={meetingsTableConfig}
        />
      ) : (
        <div>На данный момент с Вами не запланировано ни одной встречи</div>
      )}

      {(acceptDeclineMeeting || confirmMeeting) && (
        <AcceptDeclineMeetingDialog
          agentName={acceptDeclineMeetingAgentName}
          onClosePress={onCloseAcceptDeclineDialog}
          onAcceptMeeting={onAcceptMeeting}
          onDeclineMeeting={onDeclineMeeting}
          confirmMeeting={confirmMeeting}
          onRescheduleMeeting={onRescheduleMeeting}
          showTimeslotWarnig={showTimeslotWarnig}
          clearTimeslotWarning={clearTimeslotWarning}
          currentClientId={
            confirmMeeting ? confirmMeeting[MEETING_STRUCT.CUSTOMER_ID] : null
          }
        />
      )}

      {((selectedMeeting && meetingStarted) || meetingWithResults) && (
        <MeetingDialog
          onEnd={onMeetingEnd}
          agentId={
            selectedMeeting
              ? selectedMeeting[MEETING_STRUCT.REPRESENTATIVE_ID]
              : meetingWithResults[MEETING_STRUCT.REPRESENTATIVE_ID]
          }
          comments={
            selectedMeeting
              ? selectedMeeting[MEETING_STRUCT.COMMENTS]
              : meetingWithResults[MEETING_STRUCT.COMMENTS]
          }
          currentClientId={
            selectedMeeting
              ? selectedMeeting[MEETING_STRUCT.CUSTOMER_ID]
              : meetingWithResults[MEETING_STRUCT.CUSTOMER_ID]
          }
          meetingWithResults={meetingWithResults}
          onClosePress={onCloseMeetingDialog}
        />
      )}

      {selectedMeeting && investDialogVisible && (
        <InvestDialog
          onCancel={onCloseDialog}
          investIds={getClientInvestIds(selectedMeeting, invests)}
        />
      )}
      {resultDialogVisible && (
        <ResultDialog
          onCancel={onCloseDialog}
          selectedReport={selectedReport}
        />
      )}
    </>
  );
};

Meetings.propTypes = {
  currentUser: PropTypes.object.isRequired,
};
Meetings.defaultProps = {
  currentUser: {},
};

export default branch(
  {
    currentUser: meSelector(),
    agents: agentsSelector(),
    allClients: clientsSelector(),
    teams: teamsSelector(),
    events: eventsSelector(),
    invests: investsSelector(),
    meetings: meetingsSelector(),
    reports: reportsSelector(),
    clientMeetings: clientMeetingsSelector(),
    clientTimeslots: clientTimeslotsSelector(),
    clientObserverMeetings: clientObserverMeetingsSelector(),
    allTimeslots: allTimeslotsClientSelector(),
    clientAgentsMeetings: clientAgentsMeetingsSelector(),
    managerClients: managerClientsSelector(),
    meetingsResults: meetingsResultsSelector(),
  },
  Meetings,
);
