const STRUCT = {
  ID: 'id',
  NAME: 'name',
  EMAIL: 'email',
  IS_PIN_SENT: 'isPinSent',
};

export const ManagerRoles = {
  OBSERVER: 'Observer',
  CLIENT: 'Client',
};

export default STRUCT;
