import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {
  agentsGradesSelector,
  agentsSelector,
} from '../../../store/struct/selectors';
import AGENTS_GRADES_STRUCT, {
  GRADES_MEETING_STRUCT,
  SIXIB_QUESTIONS_STRUST,
  ASSERTIVENNES_GRADES_STRUCT,
  COMMENT_STRUCT,
  QUESTION_RESULT_STRUCT,
} from '../../../store/struct/entities/agentsGrades';

import Select from '../../../components/form/select';
import Table, { TYPES as TABLE_TYPES } from '../../../components/table';

import { getDate, getTime } from '../../../utils/time';

import styles from './index.module.scss';

const HEADERS_GRADES = ['Параметр', 'Среднее значение'];
const HEADERS_QUESTIONS = ['Игра/Врач', 'Ответы'];
const HEADERS_COMMENTS = ['Игра/Врач', 'Комментарий'];

const AgentGrades = ({ agentsGrades, agents, tabContentArgs }) => {
  //#region STATE
  const [currentAgent, setCurrentAgent] = useState();
  const [currentClient, setCurrentClient] = useState();
  const [currentInstitution, setCurrentInstitution] = useState();
  const [currentPosition, setCurrentPosition] = useState();
  const [resetTrigger, setResetTrigger] = useState(Date.now());
  //#endregion

  //#region FILTERS HANDLERS
  const handleChangeAgentFilter = useCallback(agent => {
    setCurrentAgent(agent);
    setCurrentClient(null);
    setCurrentInstitution(null);
    setCurrentPosition(null);
    setResetTrigger(Date.now());
  }, []);
  const onResetAgentFilter = useCallback(() => {
    setCurrentAgent(null);
    setCurrentClient(null);
    setCurrentInstitution(null);
    setCurrentPosition(null);
  }, []);
  const handleChangeClientFilter = useCallback(
    client => setCurrentClient(client),
    [],
  );
  const handleChangeInstitutionFilter = useCallback(
    institution => setCurrentInstitution(institution),
    [],
  );
  const handleChangePositionFilter = useCallback(
    position => setCurrentPosition(position),
    [],
  );
  //#endregion

  useEffect(() => {
    tabContentArgs && handleChangeAgentFilter(tabContentArgs.agentEmail);
  }, [handleChangeAgentFilter, tabContentArgs]);

  //#region FILTERS OPTIONS
  const currenAgentsMeetings = useMemo(() => {
    if (agentsGrades) {
      return agentsGrades.reduce((agentsMeetings, game) => {
        game[AGENTS_GRADES_STRUCT.MEETINGS].forEach(meeting => {
          if (
            currentAgent &&
            meeting[GRADES_MEETING_STRUCT.REPRESENTATIVE_EMAIL] === currentAgent
          ) {
            agentsMeetings.push(meeting);
          }
        });
        return agentsMeetings;
      }, []);
    }
  }, [agentsGrades, currentAgent]);

  const getUniqueParam = (array, paramId, paramTitle) => {
    return array
      .map(value => ({
        id: value[paramId],
        title: value[paramTitle],
      }))
      .filter((object, index, self) => {
        return (
          index ===
          self.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
        );
      });
  };

  const agentsOptions = useMemo(
    () => agents.map(agent => ({ id: agent.email, title: agent.name })),
    [agents],
  );

  const clientsOptions = useMemo(
    () =>
      currenAgentsMeetings &&
      getUniqueParam(
        currenAgentsMeetings
          .filter(meeting => {
            if (currentInstitution) {
              return (
                meeting[GRADES_MEETING_STRUCT.INSTITUTION_ID] ===
                currentInstitution
              );
            } else {
              return true;
            }
          })
          .filter(meeting => {
            if (currentPosition) {
              return (
                meeting[GRADES_MEETING_STRUCT.POSITION_ID] === currentPosition
              );
            } else {
              return true;
            }
          }),
        GRADES_MEETING_STRUCT.CLIENT_EMAIL,
        GRADES_MEETING_STRUCT.CLIENT_NAME,
      ),
    [currenAgentsMeetings, currentInstitution, currentPosition],
  );

  const institutionsOptions = useMemo(
    () =>
      currenAgentsMeetings &&
      getUniqueParam(
        currenAgentsMeetings
          .filter(meeting => {
            if (currentClient) {
              return (
                meeting[GRADES_MEETING_STRUCT.CLIENT_EMAIL] === currentClient
              );
            } else {
              return true;
            }
          })
          .filter(meeting => {
            if (currentPosition) {
              return (
                meeting[GRADES_MEETING_STRUCT.POSITION_ID] === currentPosition
              );
            } else {
              return true;
            }
          }),
        GRADES_MEETING_STRUCT.INSTITUTION_ID,
        GRADES_MEETING_STRUCT.INSTITUTION,
      ),
    [currenAgentsMeetings, currentClient, currentPosition],
  );

  const positionsnOptions = useMemo(
    () =>
      currenAgentsMeetings &&
      getUniqueParam(
        currenAgentsMeetings
          .filter(meeting => {
            if (currentClient) {
              return (
                meeting[GRADES_MEETING_STRUCT.CLIENT_EMAIL] === currentClient
              );
            } else {
              return true;
            }
          })
          .filter(meeting => {
            if (currentInstitution) {
              return (
                meeting[GRADES_MEETING_STRUCT.INSTITUTION_ID] ===
                currentInstitution
              );
            } else {
              return true;
            }
          }),
        GRADES_MEETING_STRUCT.POSITION_ID,
        GRADES_MEETING_STRUCT.POSITION,
      ),
    [currenAgentsMeetings, currentClient, currentInstitution],
  );
  //#endregion

  //#region TABLE DATA
  const tableData = useMemo(() => {
    const filteredMeetings =
      currenAgentsMeetings &&
      currenAgentsMeetings
        .filter(meeting => {
          if (currentClient) {
            return (
              meeting[GRADES_MEETING_STRUCT.CLIENT_EMAIL] === currentClient
            );
          } else {
            return true;
          }
        })
        .filter(meeting => {
          if (currentInstitution) {
            return (
              meeting[GRADES_MEETING_STRUCT.INSTITUTION_ID] ===
              currentInstitution
            );
          } else {
            return true;
          }
        })
        .filter(meeting => {
          if (currentPosition) {
            return (
              meeting[GRADES_MEETING_STRUCT.POSITION_ID] === currentPosition
            );
          } else {
            return true;
          }
        });

    let assertGradesCount = 0;
    const questionsArray = [];
    const assertivenessArray = [];

    if (filteredMeetings) {
      filteredMeetings.forEach(meeting => {
        if (meeting[GRADES_MEETING_STRUCT.QUESTION_FORM_RESULT].length !== 0) {
          questionsArray.push({
            date: meeting[GRADES_MEETING_STRUCT.MEETING_START],
            client: meeting[GRADES_MEETING_STRUCT.CLIENT_NAME],
            position: meeting[GRADES_MEETING_STRUCT.POSITION],
            institution: meeting[GRADES_MEETING_STRUCT.INSTITUTION],
            questionResult: meeting[GRADES_MEETING_STRUCT.QUESTION_FORM_RESULT],
          });
        }
      });

      const assertSum = filteredMeetings.reduce((acc, meeting) => {
        assertGradesCount += 1;
        return acc + meeting[GRADES_MEETING_STRUCT.ASSERTIVENESS_GRADE] || 0;
      }, 0);
      assertSum &&
        assertivenessArray.push({
          title: 'Шкала ассертивности',
          averageGrade: (assertSum / assertGradesCount).toFixed(2),
        });
    }

    return {
      SIXIB:
        filteredMeetings &&
        filteredMeetings
          .reduce((acc, value) => {
            if (value[GRADES_MEETING_STRUCT.SIXIB_RESULT]) {
              value[GRADES_MEETING_STRUCT.SIXIB_RESULT].forEach(result => {
                acc.push(result);
              });
            }
            return acc;
          }, [])
          .map((object, _, array) => {
            let gradeSum = 0;
            let gradeCount = 0;
            array.forEach(obj => {
              if (
                obj.question.toUpperCase().replace(/(\r\n|\n|\r)/gm, '') ===
                object.question.toUpperCase().replace(/(\r\n|\n|\r)/gm, '')
              ) {
                gradeSum += obj.grade;
                gradeCount += 1;
              }
            });
            return {
              question: object.question,
              averageGrade: (gradeSum / gradeCount).toFixed(2),
            };
          })
          .filter((object, index, self) => {
            return (
              index ===
              self.findIndex(
                obj =>
                  JSON.stringify({
                    question: obj.question
                      .toUpperCase()
                      .replace(/(\r\n|\n|\r)/gm, ''),
                    averageGrade: obj.averageGrade,
                  }) ===
                  JSON.stringify({
                    question: object.question
                      .toUpperCase()
                      .replace(/(\r\n|\n|\r)/gm, ''),
                    averageGrade: object.averageGrade,
                  }),
              )
            );
          }),
      ASSERTIVENESS: assertivenessArray,
      QUESTIONS: questionsArray,
      COMMENTS:
        filteredMeetings &&
        filteredMeetings.map(meeting => {
          return {
            date: meeting[GRADES_MEETING_STRUCT.MEETING_START],
            client: meeting[GRADES_MEETING_STRUCT.CLIENT_NAME],
            position: meeting[GRADES_MEETING_STRUCT.POSITION],
            institution: meeting[GRADES_MEETING_STRUCT.INSTITUTION],
            comment: meeting[GRADES_MEETING_STRUCT.COMMENT],
          };
        }),
    };
  }, [
    currenAgentsMeetings,
    currentClient,
    currentInstitution,
    currentPosition,
  ]);
  //#endregion

  //#region TABLES CONFIGS
  const sixibTableConfig = useMemo(() => {
    return [
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData => ratingData[SIXIB_QUESTIONS_STRUST.QUESTION],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData =>
          ratingData[SIXIB_QUESTIONS_STRUST.AVERAGE_GRADE],
      },
    ];
  }, []);

  const assertivenessTableConfig = useMemo(() => {
    return [
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData => ratingData[ASSERTIVENNES_GRADES_STRUCT.TITLE],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData =>
          ratingData[ASSERTIVENNES_GRADES_STRUCT.AVERAGE_GRADE],
      },
    ];
  }, []);

  const questionsTableConfig = useMemo(() => {
    return [
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData =>
          `${getDate(ratingData[QUESTION_RESULT_STRUCT.DATE])} ${getTime(
            ratingData[QUESTION_RESULT_STRUCT.DATE],
          )}, ${ratingData[QUESTION_RESULT_STRUCT.CLIENT]}, ${
            ratingData[QUESTION_RESULT_STRUCT.POSITION]
          }, ${ratingData[QUESTION_RESULT_STRUCT.INSTITUTION]}`,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData =>
          ratingData[QUESTION_RESULT_STRUCT.QUESTION_RESULT]
            .split('\n')
            .map(function(item, idx) {
              return (
                <span key={idx} className={styles.comment}>
                  {item}
                  <br />
                </span>
              );
            }),
      },
    ];
  }, []);

  const commentsTableConfig = useMemo(() => {
    return [
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData =>
          `${getDate(ratingData[COMMENT_STRUCT.DATE])} ${getTime(
            ratingData[COMMENT_STRUCT.DATE],
          )}, ${ratingData[COMMENT_STRUCT.CLIENT]}, ${
            ratingData[COMMENT_STRUCT.POSITION]
          }, ${ratingData[COMMENT_STRUCT.INSTITUTION]}`,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: ratingData => ratingData[COMMENT_STRUCT.COMMENT],
      },
    ];
  }, []);
  //#endregion

  return (
    <>
      <div className={styles.block}>
        <Select
          onChange={handleChangeAgentFilter}
          options={agentsOptions}
          value={currentAgent}
          className={styles.selectMargin}
          placeholder='Пользователь'
          selectablePlaceholder
          onReset={onResetAgentFilter}
        />
        <Select
          onChange={handleChangeClientFilter}
          options={clientsOptions}
          value={currentClient}
          className={styles.selectMargin}
          placeholder='Врач'
          selectablePlaceholder
          onReset={() => handleChangeClientFilter(null)}
          disabled={!currentAgent}
          resetTrigger={resetTrigger}
        />
        <Select
          onChange={handleChangePositionFilter}
          options={positionsnOptions}
          value={currentPosition}
          className={styles.selectMargin}
          placeholder='Должность'
          selectablePlaceholder
          onReset={() => handleChangePositionFilter(null)}
          disabled={!currentAgent}
          resetTrigger={resetTrigger}
        />
        <Select
          onChange={handleChangeInstitutionFilter}
          options={institutionsOptions}
          value={currentInstitution}
          className={styles.selectMargin}
          placeholder='Линия'
          selectablePlaceholder
          onReset={() => handleChangeInstitutionFilter(null)}
          disabled={!currentAgent}
          resetTrigger={resetTrigger}
        />
      </div>
      {!currentAgent ? (
        <div
          className={`${styles.resultsContainer} ${styles.containerBottomPadding}`}
        >
          <span>Для просмотра результатов выберите пользователя</span>
        </div>
      ) : (
        <div
          className={`${styles.resultsContainer} ${styles.containerBottomPadding}`}
        >
          {currenAgentsMeetings.length !== 0 ? (
            <Tabs>
              <TabList>
                <Tab>6IB</Tab>
                <Tab>Ассертивность</Tab>
                <Tab>Вопросы</Tab>
                <Tab>Комментарии</Tab>
              </TabList>

              <TabPanel>
                <div className={styles.marginTop}>
                  {tableData && tableData.SIXIB.length !== 0 ? (
                    <Table
                      className={styles.firstColumn80}
                      keyName={'SIXIB_TABLE'}
                      headers={HEADERS_GRADES}
                      config={sixibTableConfig}
                      data={tableData.SIXIB}
                    />
                  ) : (
                    <div>Нет результатов для отображения</div>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={styles.marginTop}>
                  {tableData && tableData.ASSERTIVENESS.length !== 0 ? (
                    <Table
                      className={styles.firstColumn40}
                      keyName={'ASSERTIVENESS_TABLE'}
                      headers={HEADERS_GRADES}
                      config={assertivenessTableConfig}
                      data={tableData.ASSERTIVENESS}
                    />
                  ) : (
                    <div>Нет результатов для отображения</div>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={styles.marginTop}>
                  {tableData && tableData.QUESTIONS.length !== 0 ? (
                    <Table
                      className={styles.tablePadding}
                      keyName={'QUESTIONS_TABLE'}
                      headers={HEADERS_QUESTIONS}
                      config={questionsTableConfig}
                      data={tableData.QUESTIONS}
                    />
                  ) : (
                    <div>Нет результатов для отображения</div>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={styles.tableColumnWidth}>
                  {tableData && tableData.COMMENTS.length !== 0 ? (
                    <Table
                      className={styles.firstColumn40}
                      keyName={'COMMENTS_TABLE'}
                      headers={HEADERS_COMMENTS}
                      config={commentsTableConfig}
                      data={tableData.COMMENTS}
                    />
                  ) : (
                    <div>Нет результатов для отображения</div>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          ) : (
            <span>Нет результатов для отображения</span>
          )}
        </div>
      )}
    </>
  );
};

export default branch(
  {
    agentsGrades: agentsGradesSelector(),
    agents: agentsSelector(),
  },
  AgentGrades,
);
