const STRUCT = {
  CREATED_AT: 'created_at',
  CUSTOMER_ID: 'customer_id',
  END: 'end',
  GAME_EXERCISE_ID: 'game_exercise_id',
  ID: 'id',
  MEETING_ID: 'meeting_id',
  START: 'start',
  UPDATED_AT: 'updated_at',
  BREAK: 'break',
};

export const BREAK_VALUES = {
  NO: 'no',
  ALL: 'all',
  CLIENT: 'client',
  UNAVAILABLE: 'unavailable',
};
export default STRUCT;
