import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { branch } from 'baobab-react/higher-order';
import find from 'lodash.find';

import {
  createMeeting,
  removeMeeting,
} from '../../../store/struct/entities/meeting/actions';
import {
  currentClientsSelector,
  currentGameSelector,
  agentMeetingsSelector,
  eventsSelector,
  investsSelector,
  meSelector,
  reportsSelector,
  timeslotsSelector,
  agentAvailableClients,
} from '../../../store/struct/selectors';
import RESULT_STRUCT from '../../../store/struct/entities/meetingResult';
import POSITION_STRUCT from '../../../store/struct/entities/position';
import INSTITUTION_STRUCT from '../../../store/struct/entities/institution';
import TIMESLOT_STRUCT, {
  BREAK_VALUES,
} from '../../../store/struct/entities/timeslots';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import MEETING_STRUCT, {
  MEETING_STATUSES,
} from '../../../store/struct/entities/meeting';
import CALCULATED_MEETING_STRUCT from '../../../store/struct/calculated/meeting';
import { updateMeeting } from '../../../store/struct/entities/meeting/actions';
import { sendRepMeetingResult } from '../../../store/struct/entities/meetingResult/actions';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import Form, {
  PARAMS,
  TYPES as FORM_TYPES,
  TYPES,
} from '../../../components/form';
import { PARAMS as SELECT_PARAMS } from '../../../components/form/select';
import Dialog from '../../../components/dialog';
import MeetingDialog from './meetingDialog';
import { THEMES } from '../../../components/button';

import {
  getUTCDate,
  getTimeRange,
  getTimeRangeArr,
  isDatesEqual,
  isDateLater,
  isTimeIntervalsIntersect,
} from '../../../utils/time';
import { getById } from '../../../utils';
import ResultDialog from '../../client/resultDialog';
import { CALCULATED_CLIENT_STRUCT } from '../../../store/struct/calculated/client';
import StatusText from '../../../components/status-text/status-text';
import Button from '../../../components/button';

import styles from './index.module.scss';
import commonStyles from '../../../styles/common.module.scss';

const dateFormat = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  weekday: 'long',
  month: 'long',
});
const HEADERS = [
  'Учреждение',
  'Должность',
  'ФИО',
  'Статус',
  'Встреча',
  // 'Действие',
];
export const INVEST_TABLE_HEADERS = [
  'Название',
  'Стоимость (у.е)',
  'Коэффициент',
  'Выбрать',
];
const KEY_NAME = 'key';
const SCHEDULED_MEETINGS_MAXIMUM_AMOUNT = 5;
const DATE = 'date';
const TIME = 'time';

const MEETING_TABLE_STRUCT = {
  ...CALCULATED_CLIENT_STRUCT,
  MEETING_INDEX: 'meetingIndex',
};

const getEntryMeetingTime = entry => {
  const meeting =
    entry[MEETING_TABLE_STRUCT.MEETINGS][
      entry[MEETING_TABLE_STRUCT.MEETING_INDEX]
    ];
  const dateString = meeting[MEETING_STRUCT.START];

  return getUTCDate(dateString).getTime();
};

const Meetings = props => {
  const {
    user,
    dispatch,
    clients = [],
    availableClients = [],
    meetings = [],
    timeslots = [],
  } = props;

  const getTimeslotDateStr = dateStr => {
    const date = getUTCDate(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${year}-${month + 1}-${day}`;
  };

  const [selectedDateForMeeting, setSelectedDateForMeeting] = useState(null);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(Date.now());
  const [meetingStarted, setMeetingStarted] = useState(true);
  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [selectedClientForMeeting, setSelectedClientForMeeting] = useState(
    false,
  );
  const [selectedReport, setSelectedReport] = useState(null);
  const [showTimeslotWarnig, setShowTimeslotWarnig] = useState(false);
  const [selectedManagerForMeeting, setSelectedManagerForMeeting] = useState(
    false,
  );

  if (selectedMeeting && !currentMeeting) {
    setMeetingStarted(false);
    setSelectedMeeting(null);
  }

  const onSetSelectedMeeting = meetingData => {
    const meetings = meetingData[MEETING_TABLE_STRUCT.MEETINGS];
    const rowMeetingIndex = meetingData[MEETING_TABLE_STRUCT.MEETING_INDEX];

    const currMeeting = meetings[rowMeetingIndex];
    dispatch(updateMeeting, {
      id: currMeeting[MEETING_STRUCT.MEETING_ID],
      timeslot_id: currMeeting[MEETING_STRUCT.ID],

      startedRep: true, // send flag of starting
    });
  };
  useEffect(() => {
    const currMeeting = meetings.find(meet => {
      return (
        meet[CALCULATED_MEETING_STRUCT.REP_ACTUAL_START] &&
        !meet[CALCULATED_MEETING_STRUCT.REP_ACTUAL_END]
      );
    });
    setCurrentMeeting(currMeeting);
    setSelectedMeeting(currMeeting);
    setMeetingStarted(true);
  }, [meetings, currentMeeting]);

  const form = useRef();

  const getBlockedTimesForDate = useCallback(
    date => {
      return timeslots
        .filter(item => {
          if (
            !item[TIMESLOT_STRUCT.MEETING_ID] ||
            !isDatesEqual(item[TIMESLOT_STRUCT.START], date)
          ) {
            return false;
          }

          return find(
            meetings,
            meeting => meeting[MEETING_STRUCT.ID] === item[TIMESLOT_STRUCT.ID],
          );
        })
        .map(item =>
          getTimeRangeArr(
            item[TIMESLOT_STRUCT.START],
            item[TIMESLOT_STRUCT.END],
          ),
        );
    },
    [meetings, timeslots],
  );

  const getTimeOptions = useCallback(
    (client, selectedDate) => {
      const clientId = client[CLIENT_STRUCT.ID];
      const now = new Date();

      return timeslots
        .filter(
          slot =>
            slot[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.ALL &&
            slot[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.CLIENT &&
            slot[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.UNAVAILABLE,
        )
        .reduce((acc, item) => {
          const start = item[TIMESLOT_STRUCT.START];
          const startDate = getUTCDate(start);
          const end = item[TIMESLOT_STRUCT.END];
          const timeRangeArr = getTimeRangeArr(start, end);
          if (
            item[TIMESLOT_STRUCT.CUSTOMER_ID] !== clientId ||
            getTimeslotDateStr(startDate) !== selectedDate ||
            (selectedDate === getTimeslotDateStr(now) &&
              now.getTime() > startDate.getTime())
          ) {
            return acc;
          }

          return acc.concat({
            [SELECT_PARAMS.ID]: item[TIMESLOT_STRUCT.ID],
            [SELECT_PARAMS.TITLE]: getTimeRange(start, end),
            [SELECT_PARAMS.DISABLED]:
              item[TIMESLOT_STRUCT.MEETING_ID] ||
              getBlockedTimesForDate(start).some(blockedTimeRangeArr =>
                isTimeIntervalsIntersect(blockedTimeRangeArr, timeRangeArr),
              ),
          });
        }, []);
    },
    [getBlockedTimesForDate, timeslots],
  );

  const getFirstAvailableOption = options => {
    for (let i = 0; i < options.length; i++) {
      if (!options[i][PARAMS.DISABLED]) {
        return options[i][PARAMS.ID];
      }
    }

    return null;
  };

  const saveMeeting = () => {
    const timeslotId = form.current.getState()[TIME];
    const timeslot = getById(timeslots, timeslotId);

    if (!timeslotId) {
      closeDialog();
      return;
    }

    if (timeslot.meeting_id) {
      setShowTimeslotWarnig(true);
    } else {
      const data = {
        timeslot_id: timeslotId,
        representative_id: user.id,
        manager_id: selectedManagerForMeeting,
        siteUrl: document.location.href,
      };

      dispatch(createMeeting, data);
      closeDialog();
    }
  };

  const closeDialog = () => {
    setSelectedClientForMeeting(false);
    setSelectedReport(null);
    setShowTimeslotWarnig(false);
    setSelectedManagerForMeeting(null);
  };

  const getClientsSelectOptions = () => {
    let tempClientsList = clients;

    if (availableClients.length > 0) {
      tempClientsList = clients.filter(clientItem => {
        const idx = availableClients.findIndex(
          clientEmail => clientEmail === clientItem[CLIENT_STRUCT.EMAIL],
        );
        return idx !== -1;
      });
    }

    const clientsList = tempClientsList.reduce((acc, curr) => {
      return acc.concat({
        [SELECT_PARAMS.ID]: curr[CLIENT_STRUCT.ID],
        [SELECT_PARAMS.TITLE]: curr[CLIENT_STRUCT.NAME],
      });
    }, []);

    return clientsList;
  };

  const getMeetingForm = () => {
    const clientId = selectedClientForMeeting;
    const data = find(getMeetingsData().endingEntries, { id: clientId });
    const dateOptions = getDateOptions(data.id);
    const availableDateOption = getFirstAvailableOption(dateOptions);
    const selectedDate = selectedDateForMeeting || availableDateOption;
    const timeOptions = getTimeOptions(data, selectedDate);
    const availableTimeOption = getFirstAvailableOption(timeOptions);
    const clientsList = getClientsSelectOptions();

    return [
      {
        [PARAMS.ID]: MEETING_STRUCT.CUSTOMER_ID,
        [PARAMS.TITLE]: 'Фамилия Имя врача:',
        [PARAMS.INITIAL_VALUE]: clientsList[0][CLIENT_STRUCT.ID],
        [PARAMS.OPTIONS]: clientsList,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
      {
        [PARAMS.ID]: DATE,
        [PARAMS.TITLE]: 'Дата встречи:',
        [PARAMS.INITIAL_VALUE]: selectedDate,
        [PARAMS.OPTIONS]: dateOptions,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
      {
        [PARAMS.ID]: TIME,
        [PARAMS.TITLE]: 'Время встречи:',
        [PARAMS.INITIAL_VALUE]: availableTimeOption,
        [PARAMS.OPTIONS]: timeOptions,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
      {
        [PARAMS.ID]: CALCULATED_CLIENT_STRUCT.POSITION,
        [PARAMS.TITLE]: 'Должность:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: `${
          data[CALCULATED_CLIENT_STRUCT.POSITION][POSITION_STRUCT.NAME]
        }, ${data[CALCULATED_CLIENT_STRUCT.INSTITUTION][
          POSITION_STRUCT.NAME
        ].toLowerCase()}`,
        [PARAMS.READONLY]: true,
      },
      {
        [PARAMS.ID]: POSITION_STRUCT.DESC,
        [PARAMS.TITLE]: 'Полномочия:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: data[POSITION_STRUCT.DESC],
        [PARAMS.READONLY]: true,
      },
    ];
  };

  const removeTableMeeting = tableRowData => {
    const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
    const rowMeetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
    dispatch(removeMeeting, {
      timeslot_id: meetings[rowMeetingIndex][MEETING_STRUCT.ID],
      manager_id: tableRowData[MEETING_TABLE_STRUCT.MANAGER_ID],
    });
  };

  const getDateOptions = clientId => {
    return timeslots.reduce((acc, item) => {
      const startDate = getUTCDate(item[TIMESLOT_STRUCT.START]);
      const dateString = getTimeslotDateStr(startDate);
      const today = new Date();

      if (
        item[TIMESLOT_STRUCT.CUSTOMER_ID] !== clientId ||
        find(acc, { [SELECT_PARAMS.ID]: dateString })
      ) {
        return acc;
      } else {
        return acc.concat({
          [SELECT_PARAMS.ID]: dateString,
          [SELECT_PARAMS.TITLE]: dateFormat.format(startDate),
          [SELECT_PARAMS.DISABLED]:
            !isDateLater(startDate, today) && !isDatesEqual(startDate, today),
        });
      }
    }, []);
  };

  const selectMeetingDate = formData => {
    const currClient = formData[MEETING_STRUCT.CUSTOMER_ID];
    const selectedDate = formData[DATE];
    if (currClient && selectedClientForMeeting !== currClient) {
      const clientObj = clients.find(
        client => client[CLIENT_STRUCT.ID] === currClient,
      );
      const timeOptions = getTimeOptions(clientObj, selectedDate);
      const availableTimeOption = getFirstAvailableOption(timeOptions);
      form.current.setState([TIME], availableTimeOption);
      setSelectedClientForMeeting(currClient);
      setSelectedManagerForMeeting(clientObj[CLIENT_STRUCT.MANAGER_ID]);
      setTimeout(() => setUpdateTrigger(Date.now()), 0);
    }
    if (selectedDateForMeeting !== selectedDate) {
      const clientObj = clients.find(
        client => client[CLIENT_STRUCT.ID] === currClient,
      );
      const timeOptions = getTimeOptions(clientObj, selectedDate);
      const availableTimeOption = getFirstAvailableOption(timeOptions);
      form.current.setState([TIME], availableTimeOption);
      setTimeout(() => setUpdateTrigger(Date.now()), 0);
    }
    setSelectedDateForMeeting(selectedDate);
  };

  const onMeetingJoin = url => {
    window.open(`${url}`, '_blank');
  };

  const tableMeetingsConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData =>
        tableRowData[CALCULATED_CLIENT_STRUCT.INSTITUTION][
          INSTITUTION_STRUCT.NAME
        ],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData =>
        tableRowData[CALCULATED_CLIENT_STRUCT.POSITION][POSITION_STRUCT.NAME],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData => tableRowData[CALCULATED_CLIENT_STRUCT.NAME],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData => {
        const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
        const rowMeetingIndex =
          tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
        if (rowMeetingIndex === null) {
          return '-';
        }

        const status = meetings[rowMeetingIndex][MEETING_STRUCT.STATUS];
        if (status === MEETING_STATUSES.inProgress) {
          return <StatusText status={status} text={'Текущая'} />;
        }

        const time = meetings[rowMeetingIndex][CALCULATED_MEETING_STRUCT.TIME];
        const date = meetings[rowMeetingIndex][CALCULATED_MEETING_STRUCT.DATE];

        return <StatusText status={status} text={`${date} ${time}`} />;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      centered: true,
      // returns index of the desired component from the components property
      getComponentIndex: tableRowData => {
        const meetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
        if (meetingIndex === null) {
          return 0;
        }
        const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
        const status = meetings[meetingIndex][MEETING_STRUCT.STATUS];

        const meetingStatusesMap = {
          [MEETING_STATUSES.done]: 3,
          [MEETING_STATUSES.inProgress]: 2,
          [MEETING_STATUSES.pending]: 1,
          [MEETING_STATUSES.needConfirmation]: 1,
        };
        return meetingStatusesMap[status];
      },
      components: [
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data => {
            setSelectedClientForMeeting(data[CLIENT_STRUCT.ID]);
            setSelectedManagerForMeeting(data[CLIENT_STRUCT.MANAGER_ID]);
            setSelectedDateForMeeting(null);
          },
          text: 'Назначить',
          theme: THEMES.PRIMARY,
          min: true,
          getProps: () => {
            const plannedMeetings = meetings.filter(
              meeting =>
                meeting[MEETING_STRUCT.STATUS] ===
                  MEETING_STATUSES.inProgress ||
                meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.pending ||
                meeting[MEETING_STRUCT.STATUS] ===
                  MEETING_STATUSES.needConfirmation,
            ).length;
            return {
              disabled: plannedMeetings >= SCHEDULED_MEETINGS_MAXIMUM_AMOUNT,
            };
          },
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: removeTableMeeting,
          text: 'Отменить',
          theme: THEMES.CANCEL,
          min: true,
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data => onMeetingJoin(data[CLIENT_STRUCT.TEAMS_URL]),
          text: 'MS Teams',
          theme: THEMES.CANCEL,
          min: true,
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => '',
        },
      ],
    },
    // {
    //   type: TABLE_TYPES.CONDITIONAL,
    //   getComponentIndex: currMeeting => {
    //     const meetingIndex = currMeeting[MEETING_TABLE_STRUCT.MEETING_INDEX];
    //     if (meetingIndex === null) {
    //       return 0;
    //     }
    //     const meetings = currMeeting[MEETING_TABLE_STRUCT.MEETINGS];
    //     const status = meetings[meetingIndex][MEETING_STRUCT.STATUS];

    //     const today = new Date();
    //     const meetingDate = meetings[meetingIndex][TIMESLOT_STRUCT.START];
    //     const isRepEndMeeting =
    //       meetings[meetingIndex][CALCULATED_MEETING_STRUCT.REP_ACTUAL_END];
    //     if (isRepEndMeeting) {
    //       return 4;
    //     }
    //     if (
    //       meetingDate &&
    //       !isDatesEqual(today, meetingDate) &&
    //       meetings[meetingIndex][CALCULATED_MEETING_STRUCT.REP_ACTUAL_START] &&
    //       meetings[meetingIndex][CALCULATED_MEETING_STRUCT.REP_ACTUAL_END]
    //     ) {
    //       return 1;
    //     }
    //     switch (status) {
    //       case MEETING_STATUSES.pending:
    //         return 2;
    //       case MEETING_STATUSES.inProgress:
    //       case MEETING_STATUSES.done:
    //         return 3;
    //       case MEETING_STATUSES.canceled:
    //         return 5;
    //       default:
    //         return 0;
    //     }
    //   },
    // components: [
    //   {
    //     type: TABLE_TYPES.TEXT,
    //     getValue: () => '',
    //   },
    //   {
    //     type: TABLE_TYPES.TEXT,
    //     getValue: () => 'Не сегодня',
    //   },
    //   {
    //     type: TABLE_TYPES.TEXT,
    //     getValue: () => 'Будущая',
    //   },
    //   {
    //     type: TABLE_TYPES.BUTTON,
    //     onClick: onSetSelectedMeeting,
    //     text: 'Начать',
    //     theme: THEMES.PRIMARY,
    //     min: true,
    //   },
    //   {
    //     type: TABLE_TYPES.TEXT,
    //     getValue: () => 'Завершена',
    //   },
    //   {
    //     type: TABLE_TYPES.TEXT,
    //     getValue: () => 'Отменена',
    //   },
    // ],
    // },
  ];
  const tableMeetingsHeaderConfig = [
    {
      props: { className: commonStyles.headerSelect },
    },
    {
      props: { className: commonStyles.headerSelect },
    },
    {},
    {},
    // {
    //   style: { className: commonStyles.headerCentered },
    // },
  ];

  const getMeetingsData = () => {
    const endingEntries = [];
    let clientsList = clients;

    if (availableClients.length > 0) {
      clientsList = clients.filter(clientItem => {
        const idx = availableClients.findIndex(
          clientEmail => clientEmail === clientItem[CLIENT_STRUCT.EMAIL],
        );
        return idx !== -1;
      });
    }

    const meetingEntries = clientsList.reduce((acc, client) => {
      const meetingsCount = client[CALCULATED_CLIENT_STRUCT.MEETINGS].length;

      for (let i = 0; i <= meetingsCount; i++) {
        const entry = {
          ...client,
          [KEY_NAME]: `${client[CLIENT_STRUCT.ID]}_${i}`,
          [MEETING_TABLE_STRUCT.MEETING_INDEX]: i < meetingsCount ? i : null,
        };

        // place records that allow to create meeting in the end of the table
        if (entry[MEETING_TABLE_STRUCT.MEETING_INDEX] !== null) {
          acc.push(entry);
        } else {
          endingEntries.push(entry);
        }
      }

      return acc;
    }, []);

    meetingEntries.sort(
      (entry1, entry2) =>
        getEntryMeetingTime(entry1) - getEntryMeetingTime(entry2),
    );

    return { meetingEntries, endingEntries };
  };

  const isSubmitDisabled = useMemo(() => {
    return !form.current || !form.current.getState()[TIME];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTrigger]);

  const onMeetingEnd = useCallback(
    ({
      gradesState,
      questionFormResult,
      assertivenessGradesState,
      comment,
      status,
    }) => {
      const gradesWithCoef = {};
      gradesState.forEach(([key, val]) => {
        gradesWithCoef[key] = val;
      });
      const assertivenessGrades = {};
      assertivenessGradesState.forEach(([key, val]) => {
        assertivenessGrades[key] = val;
      });

      dispatch(sendRepMeetingResult, {
        [RESULT_STRUCT.MEETING]: {
          id: selectedMeeting[MEETING_STRUCT.MEETING_ID],
        },
        [RESULT_STRUCT.GRADES]: gradesWithCoef,
        [RESULT_STRUCT.COMMENT]: comment,
        [RESULT_STRUCT.QUESTION_FORM_RESULT]: JSON.stringify(
          questionFormResult,
        ),
        [RESULT_STRUCT.ASSERTIVENESS_GRADES_REP]: assertivenessGrades,
        [RESULT_STRUCT.STATUS]: status,
      });
      setMeetingStarted(false);
    },
    [selectedMeeting, dispatch],
  );
  if (!user) {
    return null;
  }
  const plannedMeetings = meetings.filter(
    meeting =>
      meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.inProgress ||
      meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.pending ||
      meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.needConfirmation,
  ).length;

  const meetingButtonDisables =
    plannedMeetings >= SCHEDULED_MEETINGS_MAXIMUM_AMOUNT;

  let clientsList = clients;

  if (availableClients.length > 0) {
    clientsList = clients.filter(clientItem => {
      const idx = availableClients.findIndex(
        clientEmail => clientEmail === clientItem[CLIENT_STRUCT.EMAIL],
      );
      return idx !== -1;
    });
  }
  return (
    <>
      <div className={styles.block}>
        <Button
          onClick={() => {
            setSelectedClientForMeeting(clientsList[0][CLIENT_STRUCT.ID]);
            setSelectedManagerForMeeting(
              clientsList[0][CLIENT_STRUCT.MANAGER_ID],
            );
            setSelectedDateForMeeting(null);
          }}
          disabled={meetingButtonDisables}
        >
          {'Назначить встречу'}
        </Button>
      </div>
      <div className={styles.container}>
        {getMeetingsData().meetingEntries.length ? (
          <Table
            className={styles.tableFullHeight}
            headers={HEADERS}
            config={tableMeetingsConfig}
            headerConfig={tableMeetingsHeaderConfig}
            keyName={KEY_NAME}
            data={getMeetingsData().meetingEntries}
            filters={[0, 1]}
          />
        ) : (
          <div className={styles.emptyText}>
            Вы пока не назначили ни одной встречи
          </div>
        )}
      </div>
      {selectedClientForMeeting && (
        <Dialog
          title='Назначение встречи'
          onSubmit={showTimeslotWarnig ? closeDialog : saveMeeting}
          submitTitle={showTimeslotWarnig ? 'Закрыть' : 'Сохранить'}
          onCancel={!showTimeslotWarnig ? closeDialog : null}
          submitDisabled={isSubmitDisabled}
        >
          {showTimeslotWarnig ? (
            <div>Выбранное время уже занято</div>
          ) : (
            <Form
              key={'timeslot_form'}
              ref={form}
              config={getMeetingForm()}
              onChange={selectMeetingDate}
            />
          )}
        </Dialog>
      )}
      {/* {selectedMeeting && meetingStarted && (
        <MeetingDialog
          onEnd={onMeetingEnd}
          currentUser={
            selectedMeeting &&
            clients.find(
              client =>
                client[CLIENT_STRUCT.ID] ===
                selectedMeeting[MEETING_STRUCT.CUSTOMER_ID],
            )
          }
        />
      )} */}
      {selectedReport && (
        <ResultDialog onCancel={closeDialog} selectedReport={selectedReport} />
      )}
    </>
  );
};

export default branch(
  {
    user: meSelector(),
    reports: reportsSelector(),
    invests: investsSelector(),
    events: eventsSelector(),
    timeslots: timeslotsSelector(),
    meetings: agentMeetingsSelector(),
    clients: currentClientsSelector(),
    game: currentGameSelector(),
    availableClients: agentAvailableClients(),
  },
  Meetings,
);
