import React from 'react';
import PropTypes from 'prop-types';
import { BREAK_VALUES } from '../../store/struct/entities/timeslots';
import styles from './index.module.scss';

export const STATUSES = BREAK_VALUES;

const ColoredText = ({ text, status, className }) => {
  let statusClassName = '';
  switch (status) {
    case BREAK_VALUES.ALL:
      statusClassName = styles.redColor;
      break;
    case BREAK_VALUES.CLIENT:
      statusClassName = styles.redColor;
      break;
    case BREAK_VALUES.NO:
      statusClassName = styles.greenColor;
      break;
    default:
      break;
  }
  let textClassName = `${styles.text} ${statusClassName}`;

  return (
    <div className={`${styles.statusTextContainer} ${className}`}>
      <span className={textClassName}>{text}</span>
    </div>
  );
};

ColoredText.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string,
};

ColoredText.defaultProps = {
  status: BREAK_VALUES.NO,
  text: '',
};

export default ColoredText;
