const STRUCT = {
  CUSTOMER_ID: 'customer_id',
  END: 'end',
  ID: 'id',
  START: 'start',
  STATUS: 'status',
  STARTED: 'started',
  COMMENTS: 'comments',
  MEETING_ID: 'meeting_id',
  TIMESLOT_ID: 'timeslot_id',
  CONFIRM: 'confirm',
  // these info is only sent to the clients
  REPRESENTATIVE_ID: 'representative_id',
  // for observers meetings
  TEAMS_URL: 'teams_url',
};

export const COMMENT_STRUCT = {
  DATETIME: 'plannedStart',
  NAME: 'c_name',
  POSITION: 'c_position',
  INSTITUTION: 'c_institution',
  TEXT: 'comment',
};

export const MEETING_STATUSES = {
  pending: 'pending',
  inProgress: 'in progress',
  done: 'done',
  needConfirmation: 'need confirmation',
};

export const MEETING_TIME = 10 * 60; // 10 min

export default STRUCT;
