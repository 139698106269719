import React, { useMemo } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'prop-types';

import {
  agentsSelector,
  clientsSelector,
  observerMeetingsSelector,
  meSelector,
} from '../../../store/struct/selectors';
import MEETING_STRUCT, {
  MEETING_STATUSES,
} from '../../../store/struct/entities/meeting';
import AGENT_STRUCT from '../../../store/struct/entities/agent';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import OBSERVER_STRUCT from '../../../store/struct/entities/observer';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import { getById } from '../../../utils';

import { THEMES } from '../../../components/button';
import StatusText from '../../../components/status-text/status-text';

import styles from './index.module.scss';
import CALCULATED_MEETING_STRUCT from '../../../store/struct/calculated/meeting';
import { isDatesEqual } from '../../../utils/time';
import TIMESLOT_STRUCT from '../../../store/struct/entities/timeslots';

import commonStyles from '../../../styles/common.module.scss';

const HEADERS = ['Представитель', 'Врач', 'Статус', 'Действие'];

const Meetings = ({ agents, clients, observerMeetings, currentUser }) => {
  const upcomingMeetings = useMemo(
    () =>
      observerMeetings.filter(
        meeting =>
          meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.inProgress ||
          MEETING_STATUSES.pending,
      ),
    [observerMeetings],
  );

  const onMeetingJoin = url => {
    window.open(`${url}`, '_blank');
  };

  const meetingsTableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const userId = data[MEETING_STRUCT.REPRESENTATIVE_ID];
        const agent = getById(agents, userId);

        return agent && agent[AGENT_STRUCT.NAME];
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const userId = data[MEETING_STRUCT.CUSTOMER_ID];
        const client = getById(clients, userId);

        return client && client[CLIENT_STRUCT.NAME];
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const time = data[CALCULATED_MEETING_STRUCT.TIME];
        const date = data[CALCULATED_MEETING_STRUCT.DATE];
        const status = data[MEETING_STRUCT.STATUS];

        return <StatusText text={`${date} ${time}`} status={status} />;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      centered: true,
      getComponentIndex: currentMeeting => {
        const today = new Date();
        const meetingDate = currentMeeting[TIMESLOT_STRUCT.START];
        if (!isDatesEqual(today, meetingDate)) {
          return 0;
        }
        switch (currentMeeting[MEETING_STRUCT.STATUS]) {
          case MEETING_STATUSES.needConfirmation:
          case MEETING_STATUSES.pending:
          case MEETING_STATUSES.inProgress:
            return 2;
          default:
            return;
        }
      },
      components: [
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Не сегодня',
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Будущая',
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: data => onMeetingJoin(data[CLIENT_STRUCT.TEAMS_URL]),
          text: 'MS Teams',
          theme: THEMES.CANCEL,
          min: true,
          getProps: data => ({
            disabled:
              data[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.inProgress,
          }),
        },
      ],
    },
  ];

  const tableMeetingsHeaderConfig = [
    {},
    {},
    {},
    {
      style: { className: commonStyles.headerCentered },
    },
  ];

  const rowConfig = {
    getClassName: eventData => {
      if (currentUser[OBSERVER_STRUCT.GLOBAL]) {
        const currRep = agents.find(
          currAgent =>
            currAgent[AGENT_STRUCT.ID] ===
            eventData[MEETING_STRUCT.REPRESENTATIVE_ID],
        );
        const obsRepresentatives = JSON.parse(
          currentUser[OBSERVER_STRUCT.REPRESENTATIVES],
        );
        if (obsRepresentatives.length > 0) {
          const currRepIdx = obsRepresentatives.findIndex(
            observersAgentEmail =>
              observersAgentEmail === currRep[AGENT_STRUCT.EMAIL],
          );
          if (currRepIdx === -1) {
            return styles.highlited;
          }
        }
      }

      return;
    },
  };

  return (
    <>
      {upcomingMeetings.length ? (
        <Table
          className={styles.tableFullHeight}
          rowConfig={rowConfig}
          headers={HEADERS}
          headerConfig={tableMeetingsHeaderConfig}
          data={upcomingMeetings}
          config={meetingsTableConfig}
        />
      ) : (
        'На данный момент для просмотра не доступно ни одной встречи'
      )}
    </>
  );
};

Meetings.propTypes = {
  currentUser: PropTypes.object.isRequired,
};
Meetings.defaultProps = {
  currentUser: {},
};

export default branch(
  {
    currentUser: meSelector(),
    agents: agentsSelector(),
    clients: clientsSelector(),
    observerMeetings: observerMeetingsSelector(),
  },
  Meetings,
);
