import STRUCT from '..';
import APP_STRUCT from '.';

export const connectionStatusSelector = () => [
  STRUCT.APP,
  APP_STRUCT.CONNECTED,
];
export const authStatusSelector = () => [STRUCT.APP, APP_STRUCT.AUTHORIZED];
export const pinSendingStatusSelector = () => [
  STRUCT.APP,
  APP_STRUCT.PIN_SENDING,
];

const getAppParamSelector = paramName => [STRUCT.APP, paramName];

export const pinSelector = () => getAppParamSelector(APP_STRUCT.PIN);
export const roleSelector = () => getAppParamSelector(APP_STRUCT.ROLE);
export const managerRoleSelector = () =>
  getAppParamSelector(APP_STRUCT.MANAGER_ROLE);
export const userIdSelector = () => getAppParamSelector(APP_STRUCT.USER_ID);
export const confirmWindowSelector = () =>
  getAppParamSelector(APP_STRUCT.CONFIRM_WINDOW);
export const confirmResultSelector = () =>
  getAppParamSelector(APP_STRUCT.CONFIRM_RESULT);
