import React, { useMemo, useState, useCallback, createRef } from 'react';
import { branch } from 'baobab-react/higher-order';
import find from 'lodash.find';

import {
  clientAgentsMeetingsSelector,
  allTimeslotsClientSelector,
  managerClientsSelector,
} from '../../../../store/struct/selectors';

import CLIENT_STRUCT from '../../../../store/struct/entities/client';
import MEETING_STRUCT from '../../../../store/struct/entities/meeting';

import TIMESLOT_STRUCT, {
  BREAK_VALUES,
} from '../../../../store/struct/entities/timeslots';

import Dialog from '../../../../components/dialog';
import { THEMES } from '../../../../components/button';
import Form, { PARAMS, TYPES } from '../../../../components/form';

import { PARAMS as SELECT_PARAMS } from '../../../../components/form/select';

import styles from './index.module.scss';

import {
  getUTCDate,
  getTimeRange,
  getTimeRangeArr,
  isDatesEqual,
  isDateLater,
  isTimeIntervalsIntersect,
} from '../../../../utils/time';

const dateFormat = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  weekday: 'long',
  month: 'long',
});

const BTNS = {
  ACCEPT: 'accept',
  DECLINE: 'decline',
  RESCHEDULE: 'reschedule',
  SAVE: 'save',
};

const DATE = 'date';
const TIME = 'time';

const AcceptDeclineMeetingDialog = ({
  agentName,
  onClosePress,
  onAcceptMeeting,
  onDeclineMeeting,
  confirmMeeting,
  onRescheduleMeeting,
  timeslots,
  meetings,
  currentClientId,
  managerClients,
  showTimeslotWarnig,
  clearTimeslotWarning,
}) => {
  const [step, setStep] = useState(0);
  const [selectedDateForMeeting, setSelectedDateForMeeting] = useState(null);

  const currentUser = useMemo(() => {
    return managerClients.find(
      client => client[CLIENT_STRUCT.ID] === currentClientId,
    );
  }, [currentClientId, managerClients]);

  const formRef = createRef();

  const handleClick = useCallback(
    id => {
      if (id === BTNS.ACCEPT) {
        onAcceptMeeting();
      }
      if (id === BTNS.DECLINE) {
        onDeclineMeeting();
      }
      if (id === BTNS.RESCHEDULE) {
        setStep(1);
      }
      if (id === BTNS.SAVE) {
        onRescheduleMeeting(formRef.current.getState()[TIME]);
      }
    },
    [formRef, onAcceptMeeting, onDeclineMeeting, onRescheduleMeeting],
  );
  const buttonsConfig = useMemo(() => {
    if (step === 1) {
      return [
        {
          id: BTNS.SAVE,
          title: 'Сохранить',
          disabled: showTimeslotWarnig,
        },
      ];
    } else {
      if (confirmMeeting) {
        return [
          {
            id: BTNS.ACCEPT,
            theme: THEMES.SECONDARY,
            title: 'Принять встречу',
          },
          {
            id: BTNS.DECLINE,
            theme: THEMES.SECONDARY,
            title: 'Отклонить встречу',
          },
          {
            id: BTNS.RESCHEDULE,
            theme: THEMES.SECONDARY,
            title: 'Перенести встречу',
          },
        ];
      } else {
        return [
          {
            id: BTNS.ACCEPT,
            theme: THEMES.SECONDARY,
            title: 'Принять встречу',
          },
          {
            id: BTNS.DECLINE,
            title: 'Отклонить встречу',
          },
        ];
      }
    }
  }, [confirmMeeting, showTimeslotWarnig, step]);

  const handleBack = useCallback(() => {
    setStep(0);
    clearTimeslotWarning();
  }, [clearTimeslotWarning]);

  const getTimeslotDateStr = dateStr => {
    const date = getUTCDate(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    return `${year}-${month + 1}-${day}`;
  };

  const getDateOptions = clientId => {
    return timeslots.reduce((acc, item) => {
      const startDate = getUTCDate(item[TIMESLOT_STRUCT.START]);
      const dateString = getTimeslotDateStr(startDate);
      const today = new Date();

      if (
        item[TIMESLOT_STRUCT.CUSTOMER_ID] !== clientId ||
        find(acc, { [SELECT_PARAMS.ID]: dateString })
      ) {
        return acc;
      } else {
        return acc.concat({
          [SELECT_PARAMS.ID]: dateString,
          [SELECT_PARAMS.TITLE]: dateFormat.format(startDate),
          [SELECT_PARAMS.DISABLED]:
            !isDateLater(startDate, today) && !isDatesEqual(startDate, today),
        });
      }
    }, []);
  };

  const getFirstAvailableOption = options => {
    for (let i = 0; i < options.length; i++) {
      if (!options[i][PARAMS.DISABLED]) {
        return options[i][PARAMS.ID];
      }
    }

    return null;
  };

  const getBlockedTimesForDate = date => {
    return timeslots
      .filter(item => {
        if (
          !item[TIMESLOT_STRUCT.MEETING_ID] ||
          !isDatesEqual(item[TIMESLOT_STRUCT.START], date)
        ) {
          return false;
        }

        return find(
          meetings.filter(value => {
            const idx = managerClients.findIndex(
              client =>
                client[CLIENT_STRUCT.ID] === value[MEETING_STRUCT.CUSTOMER_ID],
            );
            return (
              value[MEETING_STRUCT.REPRESENTATIVE_ID] ===
                confirmMeeting[MEETING_STRUCT.REPRESENTATIVE_ID] || idx !== -1
            );
          }),
          meeting => {
            return meeting[MEETING_STRUCT.ID] === item[TIMESLOT_STRUCT.ID];
          },
        );
      })
      .map(item =>
        getTimeRangeArr(item[TIMESLOT_STRUCT.START], item[TIMESLOT_STRUCT.END]),
      );
  };

  const getTimeOptions = (client, selectedDate) => {
    const clientId = client[CLIENT_STRUCT.ID];
    const now = new Date();

    return timeslots
      .filter(
        slot =>
          slot[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.ALL &&
          slot[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.CLIENT &&
          slot[TIMESLOT_STRUCT.BREAK] !== BREAK_VALUES.UNAVAILABLE,
      )
      .reduce((acc, item) => {
        const start = item[TIMESLOT_STRUCT.START];
        const startDate = getUTCDate(start);
        const end = item[TIMESLOT_STRUCT.END];
        const timeRangeArr = getTimeRangeArr(start, end);

        if (
          item[TIMESLOT_STRUCT.CUSTOMER_ID] !== clientId ||
          getTimeslotDateStr(startDate) !== selectedDate ||
          (selectedDate === getTimeslotDateStr(now) &&
            now.getTime() > startDate.getTime())
        ) {
          return acc;
        }
        return acc.concat({
          [SELECT_PARAMS.ID]: item[TIMESLOT_STRUCT.ID],
          [SELECT_PARAMS.TITLE]: getTimeRange(start, end),
          [SELECT_PARAMS.DISABLED]:
            item[TIMESLOT_STRUCT.MEETING_ID] ||
            getBlockedTimesForDate(start).some(blockedTimeRangeArr =>
              isTimeIntervalsIntersect(blockedTimeRangeArr, timeRangeArr),
            ),
        });
      }, []);
  };

  const getMeetingForm = () => {
    const clientId = currentUser[CLIENT_STRUCT.ID];
    const dateOptions = getDateOptions(clientId);
    const availableDateOption = getFirstAvailableOption(dateOptions);
    const selectedDate = selectedDateForMeeting || availableDateOption;
    const timeOptions = getTimeOptions(currentUser, selectedDate);
    const availableTimeOption = getFirstAvailableOption(timeOptions);

    return [
      {
        [PARAMS.ID]: DATE,
        [PARAMS.TITLE]: 'Дата встречи:',
        [PARAMS.INITIAL_VALUE]: selectedDate,
        [PARAMS.OPTIONS]: dateOptions,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
      {
        [PARAMS.ID]: TIME,
        [PARAMS.TITLE]: 'Время встречи:',
        [PARAMS.INITIAL_VALUE]: availableTimeOption,
        [PARAMS.OPTIONS]: timeOptions,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
    ];
  };
  return (
    <Dialog
      title={
        <>
          <div
            style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}
          >
            <div style={{ marginRight: '16px' }}>
              Встреча c: <strong>{agentName}</strong>
            </div>
          </div>
        </>
      }
      onCancel={step === 0 ? onClosePress : handleBack}
      cancelTitle={step === 0 ? 'Закрыть' : 'Назад'}
      buttons={buttonsConfig}
      onClick={handleClick}
    >
      {step === 1 ? (
        <>
          {showTimeslotWarnig ? (
            <div>Выбранное время уже занято</div>
          ) : (
            <>
              <div className={styles.title}>Выберите дату и время встречи</div>
              <Form
                key={selectedDateForMeeting}
                ref={formRef}
                config={getMeetingForm()}
                onChange={formDate => setSelectedDateForMeeting(formDate[DATE])}
              />
            </>
          )}
        </>
      ) : (
        <>
          {confirmMeeting ? (
            <div>
              Необходимо принять, отклонить или перенести встречу, выберите
              действие
            </div>
          ) : (
            <div>
              Встреча была назначена на закрытый слот, выберите действие
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

export default branch(
  {
    timeslots: allTimeslotsClientSelector(),
    meetings: clientAgentsMeetingsSelector(),
    managerClients: managerClientsSelector(),
  },
  AcceptDeclineMeetingDialog,
);
