import STRUCT from '.';
import ENTITIES from './entities';
import CALCULATED from './calculated';

export const agentsSelector = () => [STRUCT.ENTITIES, ENTITIES.AGENT];
export const clientInfoSelector = () => [STRUCT.ENTITIES, ENTITIES.CLIENT_INFO];
export const clientsSelector = () => [STRUCT.ENTITIES, ENTITIES.CLIENT];
export const observersSelector = () => [STRUCT.ENTITIES, ENTITIES.OBSERVER];
export const currentGameSelector = () => [STRUCT.ENTITIES, ENTITIES.GAME];
export const eventsSelector = () => [STRUCT.ENTITIES, ENTITIES.EVENT];
export const institutionsSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.INSTITUTION,
];
export const investsSelector = () => [STRUCT.ENTITIES, ENTITIES.INVEST];
export const meSelector = () => [STRUCT.ENTITIES, ENTITIES.ME];
export const meetingsSelector = () => [STRUCT.ENTITIES, ENTITIES.MEETING];
export const positionsSelector = () => [STRUCT.ENTITIES, ENTITIES.POSITION];
export const reportsSelector = () => [STRUCT.ENTITIES, ENTITIES.REPORTS];
export const managerClientsSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.MANAGER_CLIENTS,
];

export const sixIbsCategorySelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.SIX_IBS_CATEGORY,
];

export const sixIbsParamSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.SIX_IBS_PARAM,
];

export const sixIbsLegendSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.SIX_IBS_LEGEND,
];

export const assertivenessParamsSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.ASSERTIVENESS_PARAMS,
];

export const agentsGradesSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.AGENTS_GRADES,
];

export const teamsRatingSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.TEAM_RATING,
];
export const teamsSelector = () => [STRUCT.ENTITIES, ENTITIES.TEAM];
export const timeslotsSelector = () => [STRUCT.ENTITIES, ENTITIES.TIMESLOTS];
export const allTimeslotsClientSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.ALL_TIMESLOTS_CLIENT,
];
export const clientAgentsMeetingsSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.CLIENT_AGENTS_MEETINGS,
];

export const agentAvailableClients = () => [
  STRUCT.ENTITIES,
  ENTITIES.AVAILABLE_CLIENTS,
];

export const meetingsResultsSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.MEETINGS_RESULT,
];

// Calculated
export const currentClientsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.CURRENT_CLIENTS,
];
export const agentMeetingsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.AGENT_MEETINGS,
];
export const clientMeetingsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.CLIENT_MEETINGS,
];
export const clientTimeslotsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.CLIENT_TIMESLOTS,
];
export const observerMeetingsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.OBSERVER_MEETINGS,
];
export const clientObserverMeetingsSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.CLIENT_OBSERVER_MEETINGS,
];
export const gamesSelector = () => [STRUCT.CALCULATED, CALCULATED.GAMES];
export const agentRatingSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.AGENT_RATING,
];
export const agentQueuesSelector = () => [
  STRUCT.CALCULATED,
  CALCULATED.AGENT_QUEUE,
];
export const logsSelector = () => [STRUCT.CALCULATED, CALCULATED.LOGS];
