import React, { useState, useCallback, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';

import {
  meSelector,
  currentGameSelector,
  managerClientsSelector,
} from '../../../store/struct/selectors';
import { editClient } from '../../../store/struct/entities/client/actions';

import GAME_STRUCT from '../../../store/struct/entities/game';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import { SUBTYPES } from '../../../components/form/input';

import Form, { PARAMS, TYPES as FORM_TYPES } from '../../../components/form';

import guide from './guide.pdf';
import styles from './index.module.scss';
import Button from '../../../components/button';

const Rules = props => {
  const { currentGame, clients } = props;
  const [comment, setComment] = useState('');
  const [isInitialCommentSet, setIsInitialCommentSet] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (
      clients &&
      clients.length > 0 &&
      clients[0][CLIENT_STRUCT.GAME_COMMENT] &&
      !isInitialCommentSet
    ) {
      setComment(clients[0][CLIENT_STRUCT.GAME_COMMENT]);
      setIsInitialCommentSet(true);
    }
  }, [clients, isInitialCommentSet]);

  const onStateChange = useCallback(
    e => {
      setComment(e.target.value);
      setIsButtonDisabled(false);
    },
    [setComment],
  );

  let rules = '';

  if (!currentGame) {
    return '';
  }

  if (clients) {
    clients.forEach(client => {
      rules =
        rules +
        `${client[CLIENT_STRUCT.NAME]}\n\n${client[CLIENT_STRUCT.RULES]}\n\n`;
    });
  }

  const saveGameComment = () => {
    for (const currentClient of clients) {
      const data = {
        [CLIENT_STRUCT.ID]: currentClient.id,
        [CLIENT_STRUCT.GAME_COMMENT]: comment,
      };
      props.dispatch(editClient, data);
    }
    setIsButtonDisabled(true);
  };

  return (
    <div className={`${styles.container} ${styles.containerBottomPadding}`}>
      <Form
        config={[
          {
            [PARAMS.ID]: 'game_name',
            [PARAMS.TITLE]: 'Название игры:',
            [PARAMS.INITIAL_VALUE]: currentGame[GAME_STRUCT.NAME],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_desc',
            [PARAMS.TITLE]: 'Цели и описание игры:',
            [PARAMS.INITIAL_VALUE]: currentGame[GAME_STRUCT.DESC],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.SUBTYPE]: SUBTYPES.TEXTAREA,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_capabilities_and_restrictions',
            [PARAMS.TITLE]: 'Возможности и ограничения Клиента:',
            [PARAMS.INITIAL_VALUE]: rules,
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.SUBTYPE]: SUBTYPES.TEXTAREA,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_refs',
            [PARAMS.TITLE]: 'Инструкция:',
            [PARAMS.INITIAL_VALUE]: [guide, 'Ссылка'],
            [PARAMS.TYPE]: FORM_TYPES.LINK,
            [PARAMS.READONLY]: true,
            [PARAMS.ATTRS]: {
              target: '_blank',
            },
          },
        ]}
      />
      <fieldset className={styles.fieldset}>
        <legend>Комментарий к игре</legend>
        <textarea value={comment} onChange={onStateChange} />
      </fieldset>
      <Button onClick={saveGameComment} disabled={isButtonDisabled}>
        {'Сохранить комментарий'}
      </Button>
    </div>
  );
};

export default branch(
  {
    currentGame: currentGameSelector(),
    clients: managerClientsSelector(),
  },
  Rules,
);
