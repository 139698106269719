import React from 'react';
import { branch } from 'baobab-react/higher-order';

import { meSelector } from '../../store/struct/selectors';
import Tabs from '../tabs';
import { ICONS } from '../../components/Icons';
import USER_STRUCT from '../../store/struct/entities/user';
import Rules from './rules';
import Meetings from './meetings';
import TopPanelIcon from '../../components/Icons/top-panel-icon/topPanelIcon';

import styles from './index.module.scss';

const tabs = [
  {
    title: 'Правила',
    content: Rules,
    icon: ICONS.UnionIcon,
  },
  {
    title: 'Встречи',
    content: Meetings,
    icon: ICONS.MeetIcon,
  },
];

const ObserverContainer = props => {
  const { me } = props;
  const user = me && me;
  const header = user && (
    <TopPanelIcon
      icon={<ICONS.PersonIcon width={14} height={14} />}
      title={'Наблюдатель'}
    >
      {user[USER_STRUCT.NAME]}
    </TopPanelIcon>
  );
  return (
    <>
      <div className={styles.content}>
        <Tabs tabs={tabs} {...props} header={header} />
      </div>
    </>
  );
};

export default branch(
  {
    me: meSelector(),
  },
  ObserverContainer,
);
