import React from 'react';
import PropTypes from 'prop-types';

import Button, { THEMES } from '../button';

import styles from './index.module.scss';

const Dialog = props => {
  const {
    children,
    title,
    cancelTitle,
    submitTitle,
    submitDisabled,
    onCancel,
    onSubmit,
    onClick,
    buttons,
    errorFeedback,
    className,
  } = props;

  return (
    <div className={styles.dialogContainer}>
      <div className={`${styles.dialog} ${className}`}>
        <div className={styles.head}>{title}</div>
        <div className={styles.body}>{children}</div>
        {(onCancel || onSubmit || onClick) && (
          <div className={styles.buttons}>
            {errorFeedback && (
              <div className={styles.errorFeedback}>{errorFeedback}</div>
            )}
            {onCancel && (
              <div className={styles.canceledButton}>
                <Button theme={THEMES.CANCEL} onClick={onCancel}>
                  {cancelTitle}
                </Button>
              </div>
            )}
            {onSubmit && (
              <Button
                theme={THEMES.PRIMARY}
                onClick={onSubmit}
                disabled={submitDisabled}
              >
                {submitTitle}
              </Button>
            )}
            {buttons &&
              onClick &&
              buttons.map(btn => (
                <Button
                  key={btn.id}
                  theme={btn.theme || THEMES.PRIMARY}
                  onClick={() => onClick(btn.id)}
                  disabled={btn.disabled}
                >
                  {btn.title}
                </Button>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

Dialog.propTypes = {
  title: PropTypes.any,
  cancelTitle: PropTypes.string,
  onCancel: PropTypes.func,
  submitTitle: PropTypes.string,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  buttons: PropTypes.array,
  errorFeedback: PropTypes.string,
  className: PropTypes.string,
};

Dialog.defaultProps = {
  title: '',
  cancelTitle: 'Отменить',
  submitTitle: 'Сохранить',
  submitDisabled: false,
  onCancel: null,
  onSubmit: null,
  onClick: null,
  buttons: [],
  errorFeedback: '',
  className: '',
};

export default Dialog;
