const STRUCT = {
  ID: 'id',
  CLIENT_INFO: 'clientInfo',
  MEETING: 'meeting',
  GRADES: 'grades',
  GRADES_REP: 'gradesRep',
  COMMENT: 'comment',
  COMMENT_REP: 'comment_rep',
  QUESTION_FORM_RESULT: 'question_form_result',
  ASSERTIVENESS_GRADES: 'assertiveness_grades',
  STATUS: 'status',
  CUSTOMER_ID: 'customer_id',
  MEETING_RESULT_ID: 'meeting_results_id',
  ASSERTIVENESS_GRADES_REP: 'assertivenessGrades',
};

export default STRUCT;
