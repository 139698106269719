const ENTITIES_STRUCT = {
  ADMIN: 'person',
  AGENT: 'agent',
  AGENT_RATING: 'agentRating',
  ARGUMENT: 'arguments',
  CLIENT: 'client',
  CLIENT_INFO: 'clientInfo',
  OBSERVER: 'observer',
  MANAGER: 'manager',
  EVENT: 'event',
  GAME: 'game',
  INSTITUTION: 'institution',
  INVEST: 'invest',
  ME: 'me',
  MEETING: 'meeting',
  OBSERVER_AGENTS: 'observerAgents',
  OBSERVER_MEETINGS: 'observerMeetings',
  CLIENT_AGENTS_MEETINGS: 'agentsMeetings',
  MEETING_RESULT: 'meetingresult',
  REP_MEETING_RESULT: 'repmeetingresult',
  MY_6IBS_GRADE: 'my6IBsGrade',
  MY_RATING: 'myRating',
  POSITION: 'position',
  REPORTS: 'report',
  ROLE: 'role',
  SIX_IBS_CATEGORY: 'sixIbsCategory',
  SIX_IBS_PARAM: 'sixIbsParam',
  SIX_IBS_LEGEND: 'sixIbsLegend',
  ASSERTIVENESS_PARAMS: 'assertivenessParams',
  AGENTS_GRADES: 'agentsGrades',
  STATUS: 'status',
  TEAM: 'team',
  TEAM_RATING: 'teamRating',
  TIMESLOTS: 'timeslot',
  ALL_TIMESLOTS_CLIENT: 'allTimeslots',
  LOG: 'actionlog',
  MANAGER_CLIENTS: 'managerClients',
  AVAILABLE_CLIENTS: 'availableClients',
  MEETINGS_RESULT: 'meetingsResults',
};

export default ENTITIES_STRUCT;

export const initialState = () => ({
  [ENTITIES_STRUCT.ADMIN]: [],
  [ENTITIES_STRUCT.AGENT]: [],
  [ENTITIES_STRUCT.CLIENT]: [],
  [ENTITIES_STRUCT.OBSERVER]: [],
  [ENTITIES_STRUCT.MANAGER]: [],
  [ENTITIES_STRUCT.EVENT]: [],
  [ENTITIES_STRUCT.GAME]: [],
  [ENTITIES_STRUCT.INSTITUTION]: [],
  [ENTITIES_STRUCT.INVEST]: [],
  [ENTITIES_STRUCT.MEETING]: [],
  [ENTITIES_STRUCT.MEETING_RESULT]: [],
  [ENTITIES_STRUCT.REP_MEETING_RESULT]: [],
  [ENTITIES_STRUCT.MEETING]: [],
  [ENTITIES_STRUCT.OBSERVER_AGENTS]: [],
  [ENTITIES_STRUCT.OBSERVER_MEETINGS]: [],
  [ENTITIES_STRUCT.CLIENT_AGENTS_MEETINGS]: [],
  [ENTITIES_STRUCT.POSITION]: [],
  [ENTITIES_STRUCT.ROLE]: [],
  [ENTITIES_STRUCT.SIX_IBS_CATEGORY]: [],
  [ENTITIES_STRUCT.SIX_IBS_PARAM]: [],
  [ENTITIES_STRUCT.SIX_IBS_LEGEND]: [],
  [ENTITIES_STRUCT.TEAM]: [],
  [ENTITIES_STRUCT.STATUS]: [],
  [ENTITIES_STRUCT.MY_RATING]: [],
  [ENTITIES_STRUCT.TEAM_RATING]: [],
  [ENTITIES_STRUCT.MY_6IBS_GRADE]: [],
  [ENTITIES_STRUCT.POSITION_EVENT_MAP]: [],
  [ENTITIES_STRUCT.AGENT_RATING]: [],
  [ENTITIES_STRUCT.REPORTS]: [],
  [ENTITIES_STRUCT.TIMESLOTS]: [],
  [ENTITIES_STRUCT.ALL_TIMESLOTS_CLIENT]: [],
  [ENTITIES_STRUCT.LOG]: [],
  [ENTITIES_STRUCT.AVAILABLE_CLIENTS]: [],
  [ENTITIES_STRUCT.MEETINGS_RESULT]: [],
});
