const STRUCT = {
  ID: 'id',
  NAME: 'name',
  REPRESENTATIVES: 'representatives',
  EMAIL: 'email',
  IS_PIN_SENT: 'isPinSent',
  GLOBAL: 'global',
};

export default STRUCT;
