import { getItem, removeItem, setItem } from '../utils/local-storage';

export const checkUserStorage = { CHECK_CURRENT_LOGIN: 'USER_ID' };

export const getSavedCurrentUserId = () =>
  Number(getItem(checkUserStorage.CHECK_CURRENT_LOGIN));
export const saveCurrentUserId = id =>
  setItem(checkUserStorage.CHECK_CURRENT_LOGIN, id);
export const removeCurrentUserId = () =>
  removeItem(checkUserStorage.CHECK_CURRENT_LOGIN);
