import React from 'react';
import { branch } from 'baobab-react/higher-order';
import {
  agentRatingSelector,
  agentsSelector,
  meSelector,
} from '../../../store/struct/selectors';
import USER_STRUCT from '../../../store/struct/entities/user';
import TopPanelIcon from '../../../components/Icons/top-panel-icon/topPanelIcon';
import { ICONS } from '../../../components/Icons';

const Ratings = props => {
  const { user } = props;

  if (!user) {
    return null;
  }

  return (
    user && (
      <TopPanelIcon
        icon={<ICONS.PersonIcon width={14} height={14} />}
        title={'Представитель'}
      >
        {user[USER_STRUCT.NAME]}
      </TopPanelIcon>
    )
  );
};

export default branch(
  {
    user: meSelector(),
    agents: agentsSelector(),
    agentRating: agentRatingSelector(),
  },
  Ratings,
);
