export const getUTCDate = dateStr => {
  //2020-06-01T22:07:00.000Z
  //2020-06-01 21:40:00.000
  if (dateStr instanceof Date) {
    return dateStr;
  }
  const date = new Date(dateStr);
  if (
    dateStr &&
    /^\d{4}-\d{2}-\d{2}.\d{2}:\d{2}:\d{2}\.\d{3}Z?$/.test(dateStr)
  ) {
    const year = dateStr.substr(0, 4);
    const month = dateStr.substr(5, 2);
    const day = dateStr.substr(8, 2);
    const hours = dateStr.substr(11, 2);
    const minutes = dateStr.substr(14, 2);
    const seconds = dateStr.substr(17, 2);

    date.setUTCFullYear(year);
    date.setUTCMonth(month - 1);
    date.setUTCDate(day);
    date.setUTCHours(hours);
    date.setUTCMinutes(minutes);
    date.setUTCSeconds(seconds);
  }

  return date;
};

export const getTime = dateStr => {
  const [hours, minutes] = getUTCDate(dateStr)
    .toLocaleTimeString(undefined, { hour12: false })
    .split(':');
  const addZeroPrefix = str => (str.length === 1 ? `0${str}` : str);

  return `${addZeroPrefix(hours)}:${addZeroPrefix(minutes)}`;
};

export const getDate = str => {
  return getUTCDate(str).toLocaleDateString(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
};

/**
 * Return range time
 * @param {string} dataStart
 * @param {string} dataEnd
 * @return {string}
 */
export const getTimeRange = (dataStart, dataEnd) =>
  `${getTime(dataStart)}-${getTime(dataEnd)}`;

/**
 * Return range time
 * @param {string} dataStart
 * @param {string} dataEnd
 * @return {Array}
 */
export const getTimeRangeArr = (dataStart, dataEnd) => [
  getTime(dataStart),
  getTime(dataEnd),
];

export const isDatesEqual = (date1Str, date2Str) => {
  const date1 = getUTCDate(date1Str);
  const date2 = getUTCDate(date2Str);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const isDateLater = (date1Str, date2Str) => {
  const date1 = getUTCDate(date1Str);
  const date2 = getUTCDate(date2Str);

  return (
    (date1.getDate() > date2.getDate() &&
      date1.getMonth() === date2.getMonth()) ||
    date1.getMonth() > date2.getMonth()
  );
};

/**
 * Return range time
 * @param {string[]} timeRange1
 * @param {string[]} timeRange2
 * @return {boolean}
 */
export const isTimeIntervalsIntersect = (timeRange1, timeRange2) => {
  const from1 = Number(timeRange1[0].replace(':', ''));
  const from2 = Number(timeRange2[0].replace(':', ''));
  const to1 = Number(timeRange1[1].replace(':', ''));
  const to2 = Number(timeRange2[1].replace(':', ''));

  return !(to1 < from2 || from1 > to2);
};
