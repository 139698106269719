import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { branch } from 'baobab-react/higher-order';

import { assertivenessParamsSelector } from '../../../../store/struct/selectors';
import ASSERTIVENESS_PARAM_STRUCT from '../../../../store/struct/entities/assertivenessParam';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';

import styles from '../index.module.scss';
import forOwn from 'lodash.forown';
import { PARAMS } from '../../../../components/form/select';

const DEFAULT_GRADE = 'N/A';

const OPTIONS = [
  { [PARAMS.ID]: 1, [PARAMS.TITLE]: '1' },
  { [PARAMS.ID]: 2, [PARAMS.TITLE]: '2' },
  { [PARAMS.ID]: 3, [PARAMS.TITLE]: '3' },
  { [PARAMS.ID]: 4, [PARAMS.TITLE]: '4' },
  { [PARAMS.ID]: 5, [PARAMS.TITLE]: '5' },
  { [PARAMS.ID]: 6, [PARAMS.TITLE]: '6' },
  { [PARAMS.ID]: 7, [PARAMS.TITLE]: '7' },
  { [PARAMS.ID]: 8, [PARAMS.TITLE]: '8' },
  { [PARAMS.ID]: 9, [PARAMS.TITLE]: '9' },
  { [PARAMS.ID]: 10, [PARAMS.TITLE]: '10' },
];

const Assertiveness = ({
  assertivenessParams,
  onChange,
  assertivenessGradesState,
  isResultEditing,
}) => {
  const tableData = assertivenessParams.map(param => {
    return {
      id: param[ASSERTIVENESS_PARAM_STRUCT.ID],
      name: param[ASSERTIVENESS_PARAM_STRUCT.TITLE],
    };
  });
  const [state, setState] = useState();
  const [initialStateFilled, setInitialStateFilled] = useState(false);

  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.name,
      },
      {
        type: TABLE_TYPES.SELECT,
        onChange: (data, value) => setState({ ...state, [data.id]: value }),
        getValue: data => {
          return state ? state[data.id] : 0;
        },
        placeholder: DEFAULT_GRADE,
        options: OPTIONS,
        min: true,
        showZeroValue: isResultEditing,
      },
    ],
    [isResultEditing, state],
  );

  useEffect(() => {
    const assertivenessGrades = [];
    forOwn(state, (val, key) => {
      if (!isNaN(val)) {
        assertivenessGrades.push([Number(key), Number(val)]);
      }
    });
    onChange(assertivenessGrades);
  }, [state, onChange]);

  useEffect(() => {
    if (!initialStateFilled && assertivenessGradesState.length > 0) {
      const preparedData = assertivenessGradesState.reduce((acc, curr) => {
        acc[curr[0]] = curr[1];
        return acc;
      }, {});
      setState(preparedData);
      setInitialStateFilled(true);
    }
  }, [assertivenessGradesState, initialStateFilled]);

  return (
    <Table
      className={styles.estimatesTable}
      headers={['Оценка', 'Значение']}
      config={tableConfig}
      data={tableData}
    />
  );
};

Assertiveness.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default branch(
  {
    assertivenessParams: assertivenessParamsSelector(),
  },
  Assertiveness,
);
