import React, { useMemo, useState, useCallback } from 'react';
import { branch } from 'baobab-react/higher-order';

import Timer from '../../../components/timer';
import Dialog from '../../../components/dialog';
import Estimates from './estimates';
import Assertiveness from './assertiveness';
import QuestionForm from './question-form';
import Comment from './comment';
import {
  MEETING_STATUSES,
  MEETING_TIME,
} from '../../../store/struct/entities/meeting';
import {
  sixIbsParamSelector,
  assertivenessParamsSelector,
  managerClientsSelector,
} from '../../../store/struct/selectors';

import styles from './index.module.scss';
import CLIENT_STRUCT from '../../../store/struct/entities/client';

const BTNS = {
  BACK: 'back',
  NEXT: 'next',
  SAVE: 'save',
};

const STEPS = {
  GRADES: 0,
  ASSERTIVENESS: 1,
  QUESTION_FORM: 2,
  COMMENT: 3,
};

const Step = ({ step, current, children }) => (
  <div className={step === current ? '' : styles.hidden}>{children}</div>
);

const MeetingDialog = ({ onEnd, assertivenessParams, currentUser }) => {
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState(null);
  const [gradesState, setGradesState] = useState([]);
  const [assertivenessGradesState, setAssertivenessGradesState] = useState({});
  const [questionFormResult, setQuestionForm] = useState([]);
  const [comment, setComment] = useState('');

  const questionFormString = currentUser[CLIENT_STRUCT.QUESTION_FORM];
  const sixIbsQuestions = currentUser[CLIENT_STRUCT.SIXIB_QUESTIONS];
  const sixIbsParam = useMemo(() => {
    return JSON.parse(currentUser[CLIENT_STRUCT.SIXIB_LEGEND]);
  }, [currentUser]);

  const questionsCount = useMemo(() => {
    let amount = 0;
    if (sixIbsQuestions) {
      const questionsArray = JSON.parse(sixIbsQuestions);
      questionsArray.map(value => {
        amount = amount + value.questions.length;
        return amount;
      });
    }
    return amount;
  }, [sixIbsQuestions]);

  const stepOrders = useMemo(() => {
    const arr = [STEPS.COMMENT];
    setStatus(MEETING_STATUSES.done);

    if (questionFormString) {
      arr.unshift(STEPS.QUESTION_FORM);
    }
    if (assertivenessParams) {
      arr.unshift(STEPS.ASSERTIVENESS);
    }
    if (sixIbsParam) {
      arr.unshift(STEPS.GRADES);
    }

    return arr;
  }, [sixIbsParam, questionFormString, assertivenessParams]);

  const handleClick = useCallback(
    id => {
      if (id === BTNS.BACK) {
        setStep(step - 1);
      }
      if (id === BTNS.NEXT) {
        setStep(step + 1);
      }
      if (id === BTNS.SAVE) {
        onEnd({
          gradesState,
          assertivenessGradesState,
          questionFormResult,
          comment,
          status,
        });
      }
    },
    [
      step,
      onEnd,
      questionFormResult,
      assertivenessGradesState,
      gradesState,
      comment,
      status,
    ],
  );
  const buttonsConfig = useMemo(() => {
    const btns = [];

    if (step > 0) {
      btns.push({
        id: BTNS.BACK,
        title: 'Назад',
      });
    }
    if (step < stepOrders.length - 1) {
      btns.push({
        id: BTNS.NEXT,
        title: 'Далее',
        disabled:
          (stepOrders[step] === STEPS.QUESTION_FORM &&
            questionFormResult.some(answer => answer < 0)) ||
          (stepOrders[step] === STEPS.GRADES &&
            gradesState.length < questionsCount) ||
          (stepOrders[step] === STEPS.ASSERTIVENESS &&
            assertivenessGradesState.length < assertivenessParams.length),
      });
    }
    if (step === stepOrders.length - 1) {
      btns.push({
        id: BTNS.SAVE,
        title: 'Сохранить',
      });
    }
    return btns;
  }, [
    step,
    stepOrders,
    questionFormResult,
    gradesState,
    assertivenessGradesState,
    assertivenessParams,
    questionsCount,
  ]);

  return (
    <Dialog
      title={
        <>
          <div
            style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}
          >
            <div style={{ marginRight: '16px' }}>Встреча</div>
          </div>
          <Timer time={MEETING_TIME} />
        </>
      }
      buttons={buttonsConfig}
      onClick={handleClick}
    >
      {sixIbsParam && (
        <Step step={STEPS.GRADES} current={stepOrders[step]}>
          <Estimates
            onChange={setGradesState}
            gradesState={gradesState}
            sixIbsQuestions={sixIbsQuestions}
            sixIbsLegend={currentUser[CLIENT_STRUCT.SIXIB_LEGEND]}
          />
        </Step>
      )}
      {assertivenessParams && (
        <Step step={STEPS.ASSERTIVENESS} current={stepOrders[step]}>
          <Assertiveness
            onChange={setAssertivenessGradesState}
            assertivenessGradesState={assertivenessGradesState}
          />
        </Step>
      )}
      {questionFormString && (
        <Step step={STEPS.QUESTION_FORM} current={stepOrders[step]}>
          <QuestionForm
            data={questionFormString}
            onChange={setQuestionForm}
            questionFormResult={questionFormResult}
          />
        </Step>
      )}
      <Step step={STEPS.COMMENT} current={stepOrders[step]}>
        <Comment onChange={setComment} comment={comment} />
      </Step>
    </Dialog>
  );
};

export default branch(
  {
    sixIbsParam: sixIbsParamSelector(),
    assertivenessParams: assertivenessParamsSelector(),
    managerClients: managerClientsSelector(),
  },
  MeetingDialog,
);
