import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { branch } from 'baobab-react/higher-order';

import styles from './index.module.scss';

const Comment = ({ onChange, title }) => {
  const [state, setState] = useState('');
  const onStateChange = useCallback(
    e => {
      setState(e.target.value);
    },
    [setState],
  );

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  useEffect(() => {
    if (title) {
      setState('');
    }
  }, [title]);

  return (
    <fieldset className={styles.fieldset}>
      <legend>{title ? title : 'Комментарий'}</legend>
      <textarea value={state} onChange={onStateChange} />
    </fieldset>
  );
};

Comment.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default branch({}, Comment);
