import React from 'react';

import { COMMENT_STRUCT } from '../../../../store/struct/entities/meeting';
import { getDate, getTime } from '../../../../utils/time';

import styles from './index.module.scss';

const Previous = ({ comments }) => {
  return (
    <div className={styles.comments}>
      <h3>Отзывы предыдущих игр</h3>
      <div className={styles.commentsContainer}>
        {comments.length !== 0 ? (
          comments.map((c, index) => (
            <div
              key={`Previous-comment${c.id}-${index}`}
              className={styles.commentBlock}
            >
              <p className={styles.commentInfo}>
                {getDate(c[COMMENT_STRUCT.DATETIME])}{' '}
                {getTime(c[COMMENT_STRUCT.DATETIME])}, {c[COMMENT_STRUCT.NAME]},{' '}
                {c[COMMENT_STRUCT.POSITION]}, {c[COMMENT_STRUCT.INSTITUTION]}
              </p>
              <p className={styles.comment}>{c[COMMENT_STRUCT.TEXT]}</p>
            </div>
          ))
        ) : (
          <div>Представитель не имеет завершенных встреч в прошлых играх</div>
        )}
      </div>
    </div>
  );
};

export default Previous;
