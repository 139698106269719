import React, { Component, createRef } from 'react';
import { branch } from 'baobab-react/higher-order';

import { editAgent } from '../../../store/struct/entities/agent/actions';
import { editClient } from '../../../store/struct/entities/client/actions';
import { editObserver } from '../../../store/struct/entities/observer/actions';
import { sendGamePins } from '../../../store/struct/entities/game/actions';
import {
  agentsSelector,
  clientsSelector,
  clientInfoSelector,
  institutionsSelector,
  positionsSelector,
  teamsSelector,
  observersSelector,
} from '../../../store/struct/selectors';
import STRUCT from '../../../store/struct/entities';
import USER_STRUCT from '../../../store/struct/entities/user';
import TEAM_STRUCT from '../../../store/struct/entities/team';
import INFO_STRUCT from '../../../store/struct/entities/clientInfo';
import GAME_STRUCT, {
  GAME_STATUS as STATUS,
} from '../../../store/struct/entities/game';
import { getById } from '../../../utils';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import Form, { TYPES as FORM_TYPES } from '../../../components/form';
import { SUBTYPES } from '../../../components/form/input';
import Select from '../../../components/form/select';
import Dialog from '../../../components/dialog';

import styles from '../index.module.scss';
import Button from '../../../components/button';

const HEADERS = ['ФИО', 'ПИН', 'Выслать пин', 'Роль', ''];

class Users extends Component {
  state = {
    showDialog: false,
    sendPinsUsers: [],
  };

  formConfig = [];
  formData = null;
  form = createRef();

  editUser = data => {
    this.formConfig = this.getFormConfig(data);
    this.formData = data;
    this.setState({ showDialog: true });
  };

  closeDialog = () => {
    this.formConfig = [];
    this.formData = null;
    this.setState({ showDialog: false });
  };

  saveUser = () => {
    const data = {
      [USER_STRUCT.ID]: this.formData[USER_STRUCT.ID],
      ...this.form.current.getState(),
    };

    if (USER_STRUCT.TEAM_ID in this.formData) {
      this.props.dispatch(editAgent, data);
    } else {
      // todo update info
      const { [STRUCT.CLIENT_INFO]: info, ...restData } = data;

      this.props.dispatch(editClient, restData);
    }
    this.closeDialog();
  };

  sendPinsGroup = () => {
    const { currentGameId, games } = this.props;
    const { sendPinsUsers } = this.state;
    sendPinsUsers.forEach(user => {
      const data = {
        [USER_STRUCT.ID]: user.id,
        [USER_STRUCT.IS_PIN_SENT]: true,
      };
      if (user.isAgent) {
        this.props.dispatch(editAgent, data);
      } else if (user.isObserver) {
        this.props.dispatch(editObserver, data);
      } else {
        this.props.dispatch(editClient, data);
      }
    });
    this.props.dispatch(
      sendGamePins,
      games.find(game => game[GAME_STRUCT.ID] === currentGameId),
      sendPinsUsers,
    );
    this.setState({ sendPinsUsers: [] });
  };

  tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[USER_STRUCT.NAME],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[USER_STRUCT.PIN],
    },
    {
      type: TABLE_TYPES.CHECKBOX,
      getValue: data => {
        const { sendPinsUsers } = this.state;
        if (
          data[USER_STRUCT.IS_PIN_SENT] ||
          sendPinsUsers.findIndex(user => {
            return (
              user[USER_STRUCT.ID] === data[USER_STRUCT.ID] &&
              user[USER_STRUCT.EMAIL] === data[USER_STRUCT.EMAIL] &&
              user[USER_STRUCT.POSITION_ID] === data[USER_STRUCT.POSITION_ID]
            );
          }) > -1
        ) {
          return true;
        } else {
          return false;
        }
      },
      onChange: (data, _, tableData) => {
        const { sendPinsUsers } = this.state;
        const index = sendPinsUsers.findIndex(user => {
          return (
            user[USER_STRUCT.ID] === data[USER_STRUCT.ID] &&
            user[USER_STRUCT.EMAIL] === data[USER_STRUCT.EMAIL] &&
            user[USER_STRUCT.POSITION_ID] === data[USER_STRUCT.POSITION_ID]
          );
        });
        const allUsersWithSamePin = tableData.filter(
          item => item[USER_STRUCT.PIN] === data[USER_STRUCT.PIN],
        );
        if (index > -1) {
          let tempSendPinsUsers = [...sendPinsUsers];
          allUsersWithSamePin.forEach(currUser => {
            const currIndex = tempSendPinsUsers.findIndex(user => {
              return (
                user[USER_STRUCT.ID] === currUser[USER_STRUCT.ID] &&
                user[USER_STRUCT.EMAIL] === currUser[USER_STRUCT.EMAIL] &&
                user[USER_STRUCT.POSITION_ID] ===
                  currUser[USER_STRUCT.POSITION_ID]
              );
            });
            tempSendPinsUsers = [
              ...tempSendPinsUsers.slice(0, currIndex),
              ...tempSendPinsUsers.slice(currIndex + 1),
            ];
          });
          this.setState({
            sendPinsUsers: [...tempSendPinsUsers],
          });
        } else {
          this.setState({
            sendPinsUsers: [...sendPinsUsers, ...allUsersWithSamePin],
          });
        }
      },
      getProps: data => ({
        className: styles.checkBoxContainer,
        disabled: data[USER_STRUCT.IS_PIN_SENT],
      }),
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const { positions, institutions } = this.props;

        return data.isObserver
          ? 'Наблюдатель'
          : data.isAgent
          ? 'Представитель'
          : `${getById(positions, data[USER_STRUCT.POSITION_ID]).title}, ${
              getById(institutions, data[USER_STRUCT.INSTITUTION_ID]).title
            }`;
      },
    },
    {
      type: TABLE_TYPES.BUTTON,
      onClick: this.editUser,
      text: 'Редактировать',
      min: true,
      getProps: () => ({
        disabled: this.disabled(),
      }),
    },
  ];

  getFormConfig = data => {
    const { positions, institutions, clientInfo } = this.props;

    return USER_STRUCT.POSITION_ID in data
      ? [
          {
            id: USER_STRUCT.NAME,
            title: 'ФИО пользователя:',
            type: FORM_TYPES.INPUT,
            initialValue: data[USER_STRUCT.NAME],
            className: styles.fullWidth,
          },
          {
            id: USER_STRUCT.POSITION_ID,
            title: 'Должность:',
            type: FORM_TYPES.INPUT,
            initialValue: getById(positions, data[USER_STRUCT.POSITION_ID])
              .title,
            readonly: true,
            stateless: true,
          },
          {
            id: USER_STRUCT.INSTITUTION_ID,
            title: 'Лечебное заведение:',
            type: FORM_TYPES.INPUT,
            initialValue: getById(
              institutions,
              data[USER_STRUCT.INSTITUTION_ID],
            ).title,
            readonly: true,
            stateless: true,
          },
          {
            id: USER_STRUCT.RULES,
            title: 'Возможности и ограничения Должности:',
            type: FORM_TYPES.INPUT,
            subtype: SUBTYPES.TEXTAREA,
            initialValue: data[USER_STRUCT.RULES],
            attrs: {
              rows: 5,
            },
          },
          {
            id: STRUCT.CLIENT_INFO,
            title: 'Аргументы:',
            type: FORM_TYPES.INPUT,
            subtype: SUBTYPES.TEXTAREA,
            initialValue: clientInfo
              .filter(
                info => info[INFO_STRUCT.CLIENT_ID] === data[USER_STRUCT.ID],
              )
              .map(info => info[INFO_STRUCT.BRIEF])
              .join('\n'),
            readonly: true, // todo temporarily make it readonly
            stateless: true,
            attrs: {
              rows: 5,
            },
          },
        ]
      : [
          {
            id: USER_STRUCT.NAME,
            title: 'ФИО пользователя:',
            type: FORM_TYPES.INPUT,
            initialValue: data[USER_STRUCT.NAME],
            className: styles.fullWidth,
          },
          {
            id: USER_STRUCT.GOAL,
            title: 'Целевой показатель:',
            type: FORM_TYPES.INPUT,
            initialValue: data[USER_STRUCT.GOAL],
            attrs: {
              type: 'number',
            },
          },
        ];
  };

  disabled = () => {
    const { currentGame } = this.props;

    return currentGame && currentGame[GAME_STRUCT.STATUS_ID] !== STATUS.DEFAULT;
  };

  render() {
    const {
      gamesOptions,
      currentGameId,
      setCurrentGameId,
      clients,
      agents,
      teams,
      observers,
    } = this.props;
    const { showDialog, sendPinsUsers } = this.state;

    const currentTeamsIds = teams
      .filter(team => team[TEAM_STRUCT.GAME_ID] === currentGameId)
      .map(team => team[TEAM_STRUCT.ID]);

    const currentClients = clients
      .filter(client => client[USER_STRUCT.GAME_ID] === currentGameId)
      .map(client => ({
        ...client,
        key: `${client[USER_STRUCT.ID]}_${STRUCT.CLIENT}`,
      }));
    const currentAgents = agents
      .filter(agent => currentTeamsIds.indexOf(agent[USER_STRUCT.TEAM_ID]) > -1)
      .map(client => ({
        ...client,
        key: `${client[USER_STRUCT.ID]}_${STRUCT.AGENT}`,
        isAgent: true,
      }));
    const currentObservers = observers
      .filter(observer => observer[USER_STRUCT.GAME_ID] === currentGameId)
      .map(observer => ({
        ...observer,
        key: `${observer[USER_STRUCT.ID]}_${STRUCT.OBSERVER}`,
        isObserver: true,
      }));

    return (
      <>
        <div className={styles.block}>
          <Select
            onChange={gameId => {
              setCurrentGameId(gameId);
              this.setState({ sendPinsUsers: [] });
            }}
            options={gamesOptions}
            value={currentGameId}
            attrs={{ disabled: gamesOptions.length === 0 }}
            placeholder='Выбрать игру'
          />
          <Button
            onClick={this.sendPinsGroup}
            disabled={sendPinsUsers.length === 0}
          >
            {'Выслать пины'}
          </Button>
        </div>
        {/*<h2>Список пользователей</h2>*/}
        {currentGameId !== null ? (
          <Table
            className={styles.tableWithControl}
            headers={HEADERS}
            config={this.tableConfig}
            data={currentClients.concat(currentAgents).concat(currentObservers)}
          />
        ) : (
          <div>
            Для того, чтобы открыть список пользователей, необходимо выбрать
            игру.
          </div>
        )}
        {showDialog && (
          <Dialog
            title='Редактирование пользователя'
            submitTitle='Сохранить пользователя'
            onSubmit={this.saveUser}
            cancelTitle='Отменить'
            onCancel={this.closeDialog}
          >
            <Form ref={this.form} config={this.formConfig} />
          </Dialog>
        )}
      </>
    );
  }
}

export default branch(
  {
    clients: clientsSelector(),
    agents: agentsSelector(),
    observers: observersSelector(),
    teams: teamsSelector(),
    positions: positionsSelector(),
    institutions: institutionsSelector(),
    clientInfo: clientInfoSelector(),
  },
  Users,
);
