import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

export const THEMES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  CANCEL: 'cancel',
  SUCCESS: 'success',
  ERROR: 'error',
  EDIT: 'edit',
};

const Button = ({
  onClick,
  min,
  theme,
  className,
  style,
  disabled,
  children,
}) => {
  const classNames = `${styles.button} ${styles[theme] || ''} ${
    min ? styles.min : ''
  } ${className}`;
  return (
    <button
      className={classNames}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
};

export default Button;

Button.propTypes = {
  onClick: PropTypes.func,
  theme: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.bool,
};

Button.defaultProps = {
  onClick: () => null,
  theme: THEMES.PRIMARY,
  disabled: false,
  className: '',
  min: false,
};
