const STRUCT = {
  ID: 'id',
  TITLE: 'title',
};

export const ROLES = {
  ADMIN: 1,
  AGENT: 2,
  CLIENT: 3,
  OBSERVER: 4,
  MANAGER: 5,
};

export default STRUCT;
