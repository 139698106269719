import { execAfterUserConfirmation } from '../../app/actions';
import { createRemote, updateRemote } from '../atomic';
import ENTITIES from '..';

export function sendMeetingResult(store, data) {
  createRemote(store, ENTITIES.MEETING_RESULT, data);
}

export function updateMeetingResult(store, data) {
  execAfterUserConfirmation(store, () => {
    updateRemote(store, ENTITIES.MEETING_RESULT, data);
  });
}

export function sendRepMeetingResult(store, data) {
  createRemote(store, ENTITIES.REP_MEETING_RESULT, data);
}
